<template>
  <div class="row mt-3">
    <div class="col-md-6 offset-md-3">
      <div class="card border border-dark">
        <div class="card-header bg-dark">
          <div class="card-body">
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-select
                    v-model="selectedYearFrom"
                    :items="uniqueYears"
                    label="Año Desde"
                    outlined
                    @change="fetchData"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="selectedYearTo"
                    :items="uniqueYears"
                    label="Año Hasta"
                    outlined
                    @change="fetchData"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="selectedCarrera"
                    :items="['Todos', ...uniqueCarreras]"
                    label="Carrera"
                    outlined
                    @change="handleCarreraChange"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="selectedCodigoSIES"
                    :items="filteredCodigoSIES"
                    label="Código SIES"
                    outlined
                    :disabled="!selectedCarrera || selectedCarrera === 'Todos'"
                    @change="handleSIESChange"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="selectedPlan"
                    :items="filteredPlanes"
                    label="Plan"
                    outlined
                    :disabled="!selectedCarrera || selectedCarrera === 'Todos' || !selectedCodigoSIES"
                    @change="handlePlanChange"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="selectedCodigoPLAN"
                    :items="filteredCodigoPLAN"
                    label="Código Plan"
                    outlined
                    :disabled="!selectedCarrera || selectedCarrera === 'Todos' || !selectedCodigoSIES || !selectedPlan"
                    @change="fetchData"
                  ></v-select>
                </v-col>
              </v-row>

              <v-card-title class="text-h6 text-md-h5 text-lg-h4">RETENCIONES</v-card-title>        

              <canvas id="bar-chart"></canvas>

              <v-data-table
                ref="dataTable"
                :headers="tableHeaders"
                :items="filteredRutCountTable"
                item-key="ANHO_COHORTE"
                :rows-per-page-items="[10, 25, 50, 100]"
                class="mt-3"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.ANHO_COHORTE }}</td>
                    <td>{{ item.NOMBRE_JORNADA }}</td>
                    <td>{{ item.TIPO_ACCESO_FORM_B }}</td>
                    <td>{{ item.CODIGO_PROGRAMA_SIES }}</td>
                    <!-- <td>{{ item.COD_PLAN }}</td> -->
                    <td>{{ item.NOMBRE_TIPO_CARRERA_SIES }}</td>
                    <td>{{ item.SEXO }}</td>
                    <td>{{ item.COHORTE }}</td>
                    <td>{{ item.RET1 }}</td>
                    <td>{{ item.RET_PORC_1 }}</td>
                    <td>{{ item.RET2 }}</td>
                    <td>{{ item.RET_PORC_2 }}</td>
                    <td>{{ item.RET3 }}</td>
                    <td>{{ item.RET_PORC_3 }}</td>
                    <td>{{ item.RET4 }}</td>
                    <td>{{ item.RET_PORC_4 }}</td>
                    <td>{{ item.RET5 }}</td>
                    <td>{{ item.RET_PORC_5 }}</td>
                    <td>{{ item.RET6 }}</td>
                    <td>{{ item.RET_PORC_6 }}</td>
                    <td>{{ item.RET_TOTAL }}</td>
                    <td>{{ item.RET_PORC_TOTAL }}</td>

                 <!--   <td>{{ item.COUNT }}</td>-->
                  </tr>
                </template>
              </v-data-table>

              <v-card-title class="text-h6 text-md-h5 text-lg-h4">TITULACIONES</v-card-title>      
                
              <canvas id="titulados-bar-chart"></canvas>

              <v-data-table
                  :headers="tituladosTableHeaders"
                  :items="filteredTitulacionesTable"
                  item-key="ANHO_COHORTE"
                  :rows-per-page-items="[10, 25, 50, 100]"
                  class="mt-3"
                 >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.ANHO_COHORTE }}</td>
                    <td>{{ item.NOMBRE_JORNADA }}</td>
                    <td>{{ item.TIPO_ACCESO_FORM_B }}</td>
                    <td>{{ item.CODIGO_PROGRAMA_SIES }}</td>
                    <!-- <td>{{ item.COD_PLAN }}</td> -->
                    <td>{{ item.NOMBRE_TIPO_CARRERA_SIES }}</td>
                    <td>{{ item.SEXO }}</td>
                    <td>{{ item.COHORTE }}</td>
                    <td>{{ item.TITULADOS }}</td>
                    <td>{{ item.TIT_PORC }}</td> 
                    <td>{{ item.TIT_EX }}</td>
                    <td>{{ item.TIT_EX_PORC }}</td> 
                    <td>{{ item.TIT_OP }}</td>
                    <td>{{ item.TIT_OP_PORC }}</td> 
                    <td>{{ item.TIT_SOB }}</td>
                    <td>{{ item.TIT_SOB_PORC }}</td> 
                    </tr>
                  </template>
                </v-data-table>





              <v-btn class="mt-3" color="primary" @click="exportarExcel">Exportar a Excel</v-btn>


              <template>
          <div>
            <!-- Pantalla de espera -->
            <div v-if="isLoading" class="loading-screen">
              <div class="loading-overlay">
                <div class="loading-spinner"></div>
                <p>Cargando...</p>
              </div>
            </div>

            <!-- Resto de tu contenido -->
          </div>
        </template>

            </v-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Bar } from 'vue-chartjs';
import Chart from 'chart.js/auto';
import ExcelJS from 'exceljs';

export default {
  components: {
    Bar
  },
  data() {
    return {
      chartData: null,
      chartOptions: {
        responsive: true
      },      
      selectedYearFrom: '',
      selectedYearTo: '',
      selectedCarrera: '',
      selectedPlan: '',
      selectedCodigoSIES: '',
      selectedCodigoPLAN: '',
      data: [],
      tableHeaders: [
        { text: 'Año', value: 'ANHO_COHORTE' },
        { text: 'Jornada', value: 'NOMBRE_JORNADA' },
        { text: 'Tipo de Acceso Form B', value: 'TIPO_ACCESO_FORM_B' },
        { text: 'Código Programa SIES', value: 'CODIGO_PROGRAMA_SIES' },
        // { text: 'Código Plan', value: 'COD_PLAN' },
        { text: 'Tipo Carrera SIES', value: 'NOMBRE_TIPO_CARRERA_SIES' },
        { text: 'SEXO', value: 'SEXO' },
        { text: 'COHORTE', value: 'COHORTE' },
        { text: 'RET_1', value: 'RET1' },
        { text: '%', value: 'RET_PORC_1' },
        { text: 'RET_2', value: 'RET2' },
        { text: '%', value: 'RET_PORC_2' },
        { text: 'RET_3', value: 'RET3' },
        { text: '%', value: 'RET_PORC_3' },
        { text: 'RET_4', value: 'RET4' },
        { text: '%', value: 'RET_PORC_4' },
        { text: 'RET_5', value: 'RET5' },
        { text: '%', value: 'RET_PORC_5' },
        { text: 'RET_6', value: 'RET6' },
        { text: '%', value: 'RET_PORC_6' },   
        { text: 'RET_TOTAL', value: 'RET_TOTAL' },
        { text: '%', value: 'RET_PORC_TOTAL' },        
      //  { text: 'Total', value: 'COUNT' },


      ],
      tituladosTableHeaders:[
      { text: 'Año', value: 'ANHO_COHORTE' },
        { text: 'Jornada', value: 'NOMBRE_JORNADA' },
        { text: 'Tipo de Acceso Form B', value: 'TIPO_ACCESO_FORM_B' },
        { text: 'Código Programa SIES', value: 'CODIGO_PROGRAMA_SIES' },
        // { text: 'Código Plan', value: 'COD_PLAN' },
        { text: 'Tipo Carrera SIES', value: 'NOMBRE_TIPO_CARRERA_SIES' },
        { text: 'SEXO', value: 'SEXO' },
        { text: 'COHORTE', value: 'COHORTE' },
        { text: 'Titulados totales', value: 'TITULADOS' },
        { text: '%', value: 'TIT_PORC' },
        { text: 'Titulados Exactos', value: 'TIT_EX' },
        { text: '%', value: 'TIT_EX_PORC' },
        { text: 'Titulados Oportunos', value: 'TIT_OP' },
        { text: '%', value: 'TIT_OP_PORC' },
        { text: 'Titulados Sobretiempo', value: 'TIT_SOB' },
        { text: '%', value: 'TIT_SOB_PORC' },
      ],
      isLoading: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    uniqueYears() {
      const sortedYears = [...new Set(this.data.map(item => item.ANHO_COHORTE))].sort((a, b) => a - b);
      return sortedYears;

      
    },
    uniqueCarreras() {
      const uniqueCarreras = [...new Set(this.data.map(item => item.NOMBRE_CARRERA))];
      uniqueCarreras.sort(); // Ordenar los valores de NOMBRE_CARRERA alfabéticamente
      return uniqueCarreras;
    },
    filteredPlanes() {
      if (this.selectedCodigoSIES === 'Todos') {
        return [...new Set(this.data.map(item => item.PLAN))];
      } else {
        return [...new Set(this.data
          .filter(item => item.CODIGO_PROGRAMA_SIES === this.selectedCodigoSIES)
          .map(item => item.PLAN)
        )];
      }
    },
    filteredCodigoSIES() {
      let filteredData = this.data;
      if (this.selectedCarrera && this.selectedCarrera !== 'Todos') {
        filteredData = filteredData.filter(item => item.NOMBRE_CARRERA === this.selectedCarrera);
      }

      return [...new Set(filteredData.map(item => item.CODIGO_PROGRAMA_SIES))];
    },
    filteredCodigoPLAN() {
      let filteredData = this.data;
      if (this.selectedCarrera && this.selectedCarrera !== 'Todos') {
        filteredData = filteredData.filter(item => item.NOMBRE_CARRERA === this.selectedCarrera);
      }
      if (this.selectedPlan && this.selectedPlan !== 'Todos') {
        filteredData = filteredData.filter(item => item.PLAN === this.selectedPlan);
      }
      return [...new Set(filteredData.map(item => item.COD_PLAN))];
    },
    filteredRutCountTable() {
      let filteredData = this.data;
      if (this.selectedYearFrom) {
        filteredData = filteredData.filter(item => item.ANHO_COHORTE >= this.selectedYearFrom);
      }
      if (this.selectedYearTo) {
        filteredData = filteredData.filter(item => item.ANHO_COHORTE <= this.selectedYearTo);
      }
      if (this.selectedCarrera && this.selectedCarrera !== 'Todos') {
        filteredData = filteredData.filter(item => item.NOMBRE_CARRERA === this.selectedCarrera);
      }
      if (this.selectedPlan && this.selectedPlan !== 'Todos') {
        filteredData = filteredData.filter(item => item.PLAN === this.selectedPlan);
      }
      if (this.selectedCodigoSIES && this.selectedCodigoSIES !== 'Todos') {
        filteredData = filteredData.filter(item => item.CODIGO_PROGRAMA_SIES === this.selectedCodigoSIES);
      }
      if (this.selectedCodigoPLAN && this.selectedCodigoPLAN !== 'Todos') {
        filteredData = filteredData.filter(item => item.COD_PLAN === this.selectedCodigoPLAN);
      }
      
      const rutCounts = new Map();
      filteredData.forEach((row) => {
        const ANHO_COHORTE = row.ANHO_COHORTE;
        const CODIGO_PROGRAMA_SIES = row.CODIGO_PROGRAMA_SIES;
        const NOMBRE_JORNADA = row.NOMBRE_JORNADA;
        const NOMBRE_TIPO_CARRERA_SIES = row.NOMBRE_TIPO_CARRERA_SIES;
        const TIPO_ACCESO_FORM_B = row.TIPO_ACCESO_FORM_B;
        const SEXO = row.SEXO;
        const COHORTE = row.COHORTE;
        //const RET1 = row.RET1;
        let RET1 = row.RET1;
          if (RET1 === null) {
            RET1 = 0;
          }
     //   const RET_PORC_1 = row.RET_PORC_1;
        let RET_PORC_1 = row.RET_PORC_1;
          if (RET_PORC_1 === null) {
            RET_PORC_1 = 0;
          }
        // const RET2 = row.RET2;
        // const RET_PORC_2 = row.RET_PORC_2;
        // const RET3 = row.RET3;
        // const RET_PORC_3 = row.RET_PORC_3;
        // const RET4 = row.RET4;
        // const RET_PORC_4 = row.RET_PORC_4;
        // const RET5 = row.RET5;
        // const RET_PORC_5 = row.RET_PORC_5;
        // const RET6 = row.RET6;
        // const RET_PORC_6 = row.RET_PORC_6;
        // const RET_TOTAL = row.RET_TOTAL;
        // const RET_PORC_TOTAL = row.RET_PORC_TOTAL;

        let RET2 = row.RET2;
          if (RET2 === null) {
            RET2 = 0;
          }
        let RET_PORC_2 = row.RET_PORC_2;
          if (RET_PORC_2 === null) {
            RET_PORC_2 = 0;
          }

          let RET3 = row.RET3;
          if (RET3 === null) {
            RET3 = 0;
          }
        let RET_PORC_3 = row.RET_PORC_3;
          if (RET_PORC_3 === null) {
            RET_PORC_3 = 0;
          }

          let RET4 = row.RET4;
          if (RET4 === null) {
            RET4 = 0;
          }
        let RET_PORC_4 = row.RET_PORC_4;
          if (RET_PORC_4 === null) {
            RET_PORC_4 = 0;
          }

          let RET5 = row.RET5;
          if (RET5 === null) {
            RET5 = 0;
          }
        let RET_PORC_5 = row.RET_PORC_5;
          if (RET_PORC_5 === null) {
            RET_PORC_5 = 0;
          }

          let RET6 = row.RET6;
          if (RET6 === null) {
            RET6 = 0;
          }
        let RET_PORC_6 = row.RET_PORC_6;
          if (RET_PORC_6 === null) {
            RET_PORC_6 = 0;
          }


          let RET_TOTAL = row.RET_TOTAL;
          if (RET_TOTAL === null) {
            RET_TOTAL = 0;
          }
        let RET_PORC_TOTAL = row.RET_PORC_TOTAL;
          if (RET_PORC_TOTAL === null) {
            RET_PORC_TOTAL = 0;
          }


        const key = `${CODIGO_PROGRAMA_SIES}_${SEXO}_${COHORTE}_${RET1}_${RET_PORC_1}_${RET2}_${RET_PORC_2}_${RET3}_${RET_PORC_3}_${RET4}_${RET_PORC_4}_${RET5}_${RET_PORC_5}_${RET6}_${RET_PORC_6}_${RET_TOTAL}_${RET_PORC_TOTAL}_${TIPO_ACCESO_FORM_B}_${NOMBRE_JORNADA}_${NOMBRE_TIPO_CARRERA_SIES}_${ANHO_COHORTE}`;
        if (rutCounts.has(key)) {
          rutCounts.set(key, rutCounts.get(key) + 1);
        } else {
          rutCounts.set(key, 1);
        }
      });

      const tableData = [];
      for (const [key, value] of rutCounts.entries()) {
        const [CODIGO_PROGRAMA_SIES, SEXO,COHORTE,RET1,RET_PORC_1,RET2,RET_PORC_2,RET3,RET_PORC_3,RET4,RET_PORC_4,RET5,RET_PORC_5,RET6,RET_PORC_6,RET_TOTAL,RET_PORC_TOTAL, TIPO_ACCESO_FORM_B, NOMBRE_JORNADA, NOMBRE_TIPO_CARRERA_SIES, ANHO_COHORTE] = key.split('_');
        tableData.push({
          CODIGO_PROGRAMA_SIES,
          SEXO,
          NOMBRE_JORNADA,
          NOMBRE_TIPO_CARRERA_SIES,
          TIPO_ACCESO_FORM_B,
          ANHO_COHORTE,
          COHORTE,
          RET1,
          RET_PORC_1,
          RET2,
          RET_PORC_2,
          RET3,
          RET_PORC_3,
          RET4,
          RET_PORC_4,
          RET5,
          RET_PORC_5,
          RET6,
          RET_PORC_6,
          RET_TOTAL,
          RET_PORC_TOTAL,
          COUNT: value
        });
      }

      return tableData;
    },



    filteredTitulacionesTable() {
      let filteredData = this.data;
      if (this.selectedYearFrom) {
        filteredData = filteredData.filter(item => item.ANHO_COHORTE >= this.selectedYearFrom);
      }
      if (this.selectedYearTo) {
        filteredData = filteredData.filter(item => item.ANHO_COHORTE <= this.selectedYearTo);
      }
      if (this.selectedCarrera && this.selectedCarrera !== 'Todos') {
        filteredData = filteredData.filter(item => item.NOMBRE_CARRERA === this.selectedCarrera);
      }
      if (this.selectedPlan && this.selectedPlan !== 'Todos') {
        filteredData = filteredData.filter(item => item.PLAN === this.selectedPlan);
      }
      if (this.selectedCodigoSIES && this.selectedCodigoSIES !== 'Todos') {
        filteredData = filteredData.filter(item => item.CODIGO_PROGRAMA_SIES === this.selectedCodigoSIES);
      }
      if (this.selectedCodigoPLAN && this.selectedCodigoPLAN !== 'Todos') {
        filteredData = filteredData.filter(item => item.COD_PLAN === this.selectedCodigoPLAN);
      }
      
      const rutCounts = new Map();
      filteredData.forEach((row) => {
        const ANHO_COHORTE = row.ANHO_COHORTE;
        const CODIGO_PROGRAMA_SIES = row.CODIGO_PROGRAMA_SIES;
        const NOMBRE_JORNADA = row.NOMBRE_JORNADA;
        const NOMBRE_TIPO_CARRERA_SIES = row.NOMBRE_TIPO_CARRERA_SIES;
        const TIPO_ACCESO_FORM_B = row.TIPO_ACCESO_FORM_B;
        const SEXO = row.SEXO;
        const COHORTE = row.COHORTE;
        // const TITULADOS = row.TITULADOS;
        // const TIT_PORC = row.TIT_PORC;
        // const TIT_EX = row.TIT_EX;
        // const TIT_EX_PORC = row.TIT_EX_PORC;
        // const TIT_OP = row.TIT_OP;
        // const TIT_OP_PORC = row.TIT_OP_PORC;
        // const TIT_SOB = row.TIT_SOB;
        // const TIT_SOB_PORC = row.TIT_SOB_PORC;

        let TITULADOS = row.TITULADOS;
          if (TITULADOS === null) {
            TITULADOS = 0;
          }
        let TIT_PORC = row.TIT_PORC;
          if (TIT_PORC === null) {
            TIT_PORC = 0;
          }
          let TIT_EX = row.TIT_EX;
          if (TIT_EX === null) {
            TIT_EX = 0;
          }
        let TIT_EX_PORC = row.TIT_EX_PORC;
          if (TIT_EX_PORC === null) {
            TIT_EX_PORC = 0;
          }
          let TIT_OP = row.TIT_OP;
          if (TIT_OP === null) {
            TIT_OP = 0;
          }
        let TIT_OP_PORC = row.TIT_OP_PORC;
          if (TIT_OP_PORC === null) {
            TIT_OP_PORC = 0;
          }
          let TIT_SOB = row.TIT_SOB;
          if (TIT_SOB === null) {
            TIT_SOB = 0;
          }
        let TIT_SOB_PORC = row.TIT_SOB_PORC;
          if (TIT_SOB_PORC === null) {
            TIT_SOB_PORC = 0;
          }
        const key = `${CODIGO_PROGRAMA_SIES}_${SEXO}_${COHORTE}_${TITULADOS}_${TIT_EX}_${TIT_OP}_${TIT_SOB}_${TIT_PORC}_${TIT_EX_PORC}_${TIT_OP_PORC}_${TIT_SOB_PORC}_${TIPO_ACCESO_FORM_B}_${NOMBRE_JORNADA}_${NOMBRE_TIPO_CARRERA_SIES}_${ANHO_COHORTE}`;
        if (rutCounts.has(key)) {
          rutCounts.set(key, rutCounts.get(key) + 1);
        } else {
          rutCounts.set(key, 1);
        }
      });

      const tableData = [];
      for (const [key, value] of rutCounts.entries()) {
        const [CODIGO_PROGRAMA_SIES, SEXO,COHORTE,TITULADOS,TIT_EX,TIT_OP,TIT_SOB,TIT_PORC,TIT_EX_PORC,TIT_OP_PORC,TIT_SOB_PORC, TIPO_ACCESO_FORM_B, NOMBRE_JORNADA, NOMBRE_TIPO_CARRERA_SIES, ANHO_COHORTE] = key.split('_');
        tableData.push({
          CODIGO_PROGRAMA_SIES,
          SEXO,
          NOMBRE_JORNADA,
          NOMBRE_TIPO_CARRERA_SIES,
          TIPO_ACCESO_FORM_B,
          ANHO_COHORTE,
          COHORTE,
          TITULADOS,
          TIT_PORC,
          TIT_EX,
          TIT_EX_PORC,
          TIT_OP,
          TIT_OP_PORC,
          TIT_SOB,
          TIT_SOB_PORC,
          COUNT: value
        });
      }

      return tableData;
    },



  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      try {
      //  const response = await axios.get('http://158.170.66.74:8080/admitidos/listAdm');
      const response = await axios.get('http://158.170.66.74:8080/admitidos/listRetenciones');

     //   const response = await axios.get('http://localhost:4000/admitidos/listRetenciones');

        this.data = response.data;
        let filteredData = this.data;
      if (this.selectedYearFrom) {
        filteredData = filteredData.filter(item => item.ANHO_COHORTE >= this.selectedYearFrom);
      }
      if (this.selectedYearTo) {
        filteredData = filteredData.filter(item => item.ANHO_COHORTE <= this.selectedYearTo);
      }
      if (this.selectedCarrera && this.selectedCarrera !== 'Todos') {
        filteredData = filteredData.filter(item => item.NOMBRE_CARRERA === this.selectedCarrera);
      }
      if (this.selectedPlan && this.selectedPlan !== 'Todos') {
        filteredData = filteredData.filter(item => item.PLAN === this.selectedPlan);
      }
      if (this.selectedCodigoSIES && this.selectedCodigoSIES !== 'Todos') {
        filteredData = filteredData.filter(item => item.CODIGO_PROGRAMA_SIES === this.selectedCodigoSIES);
      }
      if (this.selectedCodigoPLAN && this.selectedCodigoPLAN !== 'Todos') {
        filteredData = filteredData.filter(item => item.COD_PLAN === this.selectedCodigoPLAN);
      }
        const rutCounts = new Map();
        const ret1Average = new Map();
        const ret2Average = new Map();
        const ret3Average = new Map();
        const ret4Average = new Map();
        const ret5Average = new Map();
        const ret6Average = new Map();
        const retTotalAverage = new Map();

        const titAverage = new Map();
        const tit_exAverage = new Map();
        const tit_opAverage = new Map();
        const tit_sobAverage = new Map();

        const duracion_anhos=new Map();

        filteredData.forEach((row) => {
          const ANHO_COHORTE = row.ANHO_COHORTE;
     //     const RUT = row.RUT;
          const RET_PORC_1_A = row.RET_PORC_1_A;
          const RET_PORC_2_A = row.RET_PORC_2_A;
          const RET_PORC_3_A = row.RET_PORC_3_A;
          const RET_PORC_4_A = row.RET_PORC_4_A;
          const RET_PORC_5_A = row.RET_PORC_5_A;
          const RET_PORC_6_A = row.RET_PORC_6_A;
          const RET_PORC_TOTAL_A = row.RET_PORC_TOTAL_A;

          const TIT_PORC_A = row.TIT_PORC_A;
          const TIT_EX_PORC_A = row.TIT_EX_PORC_A;
          const TIT_OP_PORC_A = row.TIT_OP_PORC_A;
          const TIT_SOB_PORC_A = row.TIT_SOB_PORC_A;

          const DURACION_ANHOS = row.DURACION_ANHOS;

        
          if (rutCounts.has(ANHO_COHORTE)) {
            rutCounts.set(ANHO_COHORTE, rutCounts.get(ANHO_COHORTE) + 1);
            ret1Average.set(ANHO_COHORTE, ret1Average.get(ANHO_COHORTE) + RET_PORC_1_A);
            ret2Average.set(ANHO_COHORTE, ret2Average.get(ANHO_COHORTE) + RET_PORC_2_A);
            ret3Average.set(ANHO_COHORTE, ret3Average.get(ANHO_COHORTE) + RET_PORC_3_A);
            ret4Average.set(ANHO_COHORTE, ret4Average.get(ANHO_COHORTE) + RET_PORC_4_A);
            ret5Average.set(ANHO_COHORTE, ret5Average.get(ANHO_COHORTE) + RET_PORC_5_A);
            ret6Average.set(ANHO_COHORTE, ret6Average.get(ANHO_COHORTE) + RET_PORC_6_A);
            retTotalAverage.set(ANHO_COHORTE, retTotalAverage.get(ANHO_COHORTE) + RET_PORC_TOTAL_A);

            titAverage.set(ANHO_COHORTE, titAverage.get(ANHO_COHORTE) + TIT_PORC_A);
            tit_exAverage.set(ANHO_COHORTE, tit_exAverage.get(ANHO_COHORTE) + TIT_EX_PORC_A);
            tit_opAverage.set(ANHO_COHORTE, tit_opAverage.get(ANHO_COHORTE) + TIT_OP_PORC_A);
            tit_sobAverage.set(ANHO_COHORTE, tit_sobAverage.get(ANHO_COHORTE) + TIT_SOB_PORC_A);

            duracion_anhos.set(ANHO_COHORTE, duracion_anhos.get(ANHO_COHORTE) + DURACION_ANHOS);


          } else {
            rutCounts.set(ANHO_COHORTE, 1);
            ret1Average.set(ANHO_COHORTE, RET_PORC_1_A);
            ret2Average.set(ANHO_COHORTE, RET_PORC_2_A);
            ret3Average.set(ANHO_COHORTE, RET_PORC_3_A);
            ret4Average.set(ANHO_COHORTE, RET_PORC_4_A);
            ret5Average.set(ANHO_COHORTE, RET_PORC_5_A);
            ret6Average.set(ANHO_COHORTE, RET_PORC_6_A);
            retTotalAverage.set(ANHO_COHORTE, RET_PORC_TOTAL_A);

            titAverage.set(ANHO_COHORTE, TIT_PORC_A);
            tit_exAverage.set(ANHO_COHORTE, TIT_EX_PORC_A);
            tit_opAverage.set(ANHO_COHORTE, TIT_OP_PORC_A);
            tit_sobAverage.set(ANHO_COHORTE, TIT_SOB_PORC_A);

            duracion_anhos.set(ANHO_COHORTE, DURACION_ANHOS);


          }
        });



        const sortedLabels = Array.from(rutCounts.keys()).sort((a, b) => a - b);

        this.chartData = {
        labels: sortedLabels,
        datasets: [
        {
          label: 'RET_TOTAL',
          data: sortedLabels.map(label => retTotalAverage.get(label) / rutCounts.get(label)),
          backgroundColor: 'rgba(77, 77, 255, 1)'
        },
      {
      label: 'RET_1',
      data: sortedLabels.map(label => ret1Average.get(label) / rutCounts.get(label)),
      backgroundColor: 'rgba(230, 230, 255, 1)'
      },
     {
          label: 'RET_2',
          data: sortedLabels.map(label => ret2Average.get(label) / rutCounts.get(label)),
          backgroundColor: 'rgba(204, 204, 255, 1)'
        },
        {
          label: 'RET_3',
          data: sortedLabels.map(label => ret3Average.get(label) / rutCounts.get(label)),
          backgroundColor: 'rgba(179, 179, 255, 1)'
        },
        {
          label: 'RET_4',
          data: sortedLabels.map(label => ret4Average.get(label) / rutCounts.get(label)),
          backgroundColor: 'rgba(153, 153, 255, 1)'
        },
        {
          label: 'RET_5',
          data: sortedLabels.map(label => ret5Average.get(label) / rutCounts.get(label)),
          backgroundColor: 'rgba(128, 128, 255, 1)'
        },
        {
          label: 'RET_6',
          data: sortedLabels.map(label => ret6Average.get(label) / rutCounts.get(label)),
          backgroundColor: 'rgba(102, 102, 255, 1)'
        }
        ].filter(dataset => dataset !== null) // Filtramos los datasets nulos
      };

        if (this.chart) {
          this.chart.destroy();
        }

        const ctx = document.getElementById('bar-chart').getContext('2d');
        this.chart = new Chart(ctx, {
          type: 'bar',
          data: this.chartData,
          options: this.chartOptions
        });
    





        this.chartDataTit = {
        labels: sortedLabels,
        datasets: [
        {
          label: 'Totales',
          data: sortedLabels.map(label => titAverage.get(label) / rutCounts.get(label)),
          backgroundColor: 'rgba(255, 165, 0, 0.85)'
        },
        {
          label: 'Exactos"',
          data: sortedLabels.map(label => tit_exAverage.get(label) / rutCounts.get(label)),
          backgroundColor: 'rgba(255, 165, 0, 0.4)'
        },
        {
          label: 'Oportunos',
          data: sortedLabels.map(label => tit_opAverage.get(label) / rutCounts.get(label)),
          backgroundColor: 'rgba(255, 165, 0, 0.55'
        },
        {
          label: 'Sobretiempo',
          data: sortedLabels.map(label => tit_sobAverage.get(label) / rutCounts.get(label)),
          backgroundColor: 'rgba(255, 165, 0, 0.7)'
        }
        ].filter(dataset => dataset !== null) // Filtramos los datasets nulos
      };

        if (this.chartTit) {
          this.chartTit.destroy();
        }

        const ctxTit = document.getElementById('titulados-bar-chart').getContext('2d');
        this.chartTit = new Chart(ctxTit, {
          type: 'bar',
          data: this.chartDataTit,
          options: this.chartOptions
        });




      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    handleCarreraChange() {
      if (this.selectedCarrera === 'Todos') {
        this.selectedPlan = '';
        this.selectedCodigoSIES = '';
        this.selectedCodigoPLAN = '';
      }
      this.fetchData();
    },
    handleSIESChange() {
      this.selectedPlan = '';
      this.selectedCodigoPLAN = '';
      this.fetchData();
    },
    handlePlanChange() {
      if (this.selectedPlan === 'Todos') {
        this.selectedCodigoPLAN = '';
      }
      this.fetchData();
    },


    exportarExcel() {
  // Crear un nuevo workbook de Excel
  const workbook = new ExcelJS.Workbook();

    // Crear una nueva hoja de cálculo para el gráfico
    const worksheetChart = workbook.addWorksheet('Datos de gráfico retenciones');

// Agregar los datos del gráfico al worksheet
const labels = this.chartData.labels;
const datasets = this.chartData.datasets;

  // Agregar los encabezados de las columnas del gráfico
  worksheetChart.addRow(['Año Cohorte', 'Ret. Total', 'RET_1', 'RET_2', 'RET_3', 'RET_4', 'RET_5', 'RET_6']);

// Agregar los datos del gráfico al worksheet
for (let i = 0; i < labels.length; i++) {
  const label = labels[i];
  const ret_total = datasets[0].data[i];
  const ret1 = datasets[1].data[i];
  const ret2 = datasets[2].data[i];
  const ret3 = datasets[3].data[i];
  const ret4 = datasets[4].data[i];
  const ret5 = datasets[5].data[i];
  const ret6 = datasets[6].data[i];
  worksheetChart.addRow([label, ret_total, ret1, ret2, ret3, ret4, ret5, ret6]);
}


  // Crear una nueva hoja de cálculo para la tabla
  const worksheetTable = workbook.addWorksheet('Detalle de retenciones');

  // Agregar los encabezados de las columnas de la tabla
  const headers = this.tableHeaders.map(header => header.text);
  worksheetTable.addRow(headers);

  // Agregar los datos de la tabla al worksheet
  this.filteredRutCountTable.forEach(item => {
    const row = [];
    this.tableHeaders.forEach(header => {
      row.push(item[header.value]);
    });
    worksheetTable.addRow(row);
  });


  
  // Crear una nueva hoja de cálculo para el gráfico titulados
  const worksheetChart_tit = workbook.addWorksheet('Datos de gráfico titulaciones');

  // Agregar los datos del gráfico al worksheet
  const labels_tit = this.chartDataTit.labels;
  const datasets_tit = this.chartDataTit.datasets;
  
    // Agregar los encabezados de las columnas del gráfico
    worksheetChart_tit.addRow(['Año Cohorte', 'Tit. Total', 'Tit. Exacta', 'Tit. Oportuna', 'Tit. Sobretiempo']);

// Agregar los datos del gráfico al worksheet
for (let i = 0; i < labels_tit.length; i++) {
    const label = labels_tit[i];
    const tit_total = datasets_tit[0].data[i];
    const tit_ex = datasets_tit[1].data[i];
    const tit_op = datasets_tit[2].data[i];
    const tit_sob = datasets_tit[3].data[i];
    worksheetChart_tit.addRow([label, tit_total, tit_ex, tit_op, tit_sob]);
  }



// Crear una nueva hoja de cálculo para la tabla de titulados
const worksheetTitulados = workbook.addWorksheet('Detalle Titulaciones');

// Agregar los encabezados de las columnas de la tabla de titulados
const tituladosTableHeaders = this.tituladosTableHeaders.map(header => header.text);
worksheetTitulados.addRow(tituladosTableHeaders);

// Agregar los datos de la tabla de titulados al worksheet
this.filteredTitulacionesTable.forEach(item => {
  const row = [];
  this.tituladosTableHeaders.forEach(header => {
    row.push(item[header.value]);
  });
  worksheetTitulados.addRow(row);
});


  // Configurar el estilo de las celdas de la tabla retenciones
  worksheetTable.eachRow({ includeEmpty: true }, (row, rowNumber) => {
    row.eachCell((cell, colNumber) => {
      cell.font = { name: 'Arial', size: 10 };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });
  });

  // Configurar el estilo de las celdas del gráfico retenciones
  worksheetChart.eachRow({ includeEmpty: true }, (row, rowNumber) => {
    row.eachCell((cell, colNumber) => {
      cell.font = { name: 'Arial', size: 10 };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });
  });


    // Configurar el estilo de las celdas de la tabla titulaciones
    worksheetTitulados.eachRow({ includeEmpty: true }, (row, rowNumber) => {
    row.eachCell((cell, colNumber) => {
      cell.font = { name: 'Arial', size: 10 };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });
  });

  // Configurar el estilo de las celdas del gráfico titulaciones
  worksheetChart_tit.eachRow({ includeEmpty: true }, (row, rowNumber) => {
    row.eachCell((cell, colNumber) => {
      cell.font = { name: 'Arial', size: 10 };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });
  });

  // Crear un Blob con los datos del workbook
  workbook.xlsx.writeBuffer().then(buffer => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);

    // Crear un enlace temporal y hacer clic en él para descargar el archivo
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'datos.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Liberar el objeto URL
    window.URL.revokeObjectURL(url);
  });
}

  }
};
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(151, 146, 146, 0.5); /* Fondo semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Asegura que el overlay esté por encima de otros elementos */
}

.loading-spinner {
  border: 4px solid #706e6e;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin-bottom: 16px;
}
</style>