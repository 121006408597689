<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      app v-if='logueado'
      class="grey lighten-3"
    >
    <!-- <template>
    <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      app
      v-if="logueado"
    > -->
    <div>
    <v-list>
          <!-- <v-list-item class="px-2">
            <v-list-item-avatar>
              <v-img src="./assets/logo-usach_0.png"></v-img>
            </v-list-item-avatar>
          </v-list-item> -->
          
          <v-img src="./assets/logo-usach_0.png" height="100" width="100" ></v-img>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{ email }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ rol }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
        <v-divider></v-divider>

        <v-list
          nav
          dense
        >
        <v-list-group  v-if="esAdmin || esSuperadmin || esUsuario">
          <v-list-item slot="activator">
          <v-list-item-content>
          <v-list-item-title>
            <v-icon large color="#00A499">mdi-view-list</v-icon>
            Mantenedor Tabla CPP
          </v-list-item-title> 
        </v-list-item-content>   
          </v-list-item>
          <v-list-item :to="{name: 'CppView'}">
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Carreras Programas Planes</v-list-item-title>
          </v-list-item>
        </v-list-group>        
        <v-list-group  v-if="esAdmin || esSuperadmin || esUsuario">
          <v-list-item slot="activator">
          <v-list-item-content>
          <v-list-item-title>
            <v-icon large color="#00A499">mdi-view-list</v-icon>
            M.T.D CPP
          </v-list-item-title> 
        </v-list-item-content>   
          </v-list-item>
          <v-list-item :to="{name: 'Carreras'}">
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Carreras</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'JornadaSIES'}">
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Jornada SIES</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'JornadaUsach'}">
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Jornada USACH</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'ModalidadCarrera'}">
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Modalidad Carrera</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'NivelCarrera'}">
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Nivel Carrera SIES</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'NivelGlobal'}">
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Nivel Global</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'RegimenSIES'}">
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Régimen SIES</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'TiposCarreras'}">
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Tipo Carrera</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'Unidades'}">
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Centros de Costo</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'VigenciaCarreraSIES'}">
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Vigencia Carrera SIES</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'AreaActualSIES'}">
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Area Actual SIES</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'AcreditacionSIES'}">
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Acreditación SIES</v-list-item-title>
          </v-list-item>       
          <v-list-item :to="{name: 'TipoProgramaSIES'}">
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Tipo Programa SIES</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'AreaConocimientoSIES'}">
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Área Conocimiento SIES</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'AreaOECDSIES'}">
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Área Conocimiento OECD</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'SubareaOECDSIES'}">
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Subarea Conocimiento OECD</v-list-item-title>
          </v-list-item>                    
                                           
        </v-list-group>



        <v-list-group  v-if="esAdmin || esSuperadmin || esUsuario">
          <v-list-item slot="activator">
          <v-list-item-content>
          <v-list-item-title>
            <v-icon large color="#00A499">mdi-view-list</v-icon>
            M.T.D Estudiantes
          </v-list-item-title> 
        </v-list-item-content>   
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>País</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Región</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Comuna</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Situación</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Calidad</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Beneficios</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Tipo beneficio</v-list-item-title>
          </v-list-item>          
        </v-list-group>




        <v-list-group  v-if="esAdmin || esSuperadmin || esUsuario">
          <v-list-item slot="activator">
          <v-list-item-content>
          <v-list-item-title>
            <v-icon large color="#00A499">mdi-view-list</v-icon>
            M.T.D DDI_DATOS
          </v-list-item-title> 
        </v-list-item-content>   
          </v-list-item>
          <v-list-item :to="{name: 'IngPrimerAnho'}">
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Ingreso Primer Año</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'DefAnhoAcademico'}">
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Definición Año Académico</v-list-item-title>
          </v-list-item>          
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Región</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Comuna</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Situación</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Calidad</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Beneficios</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Tipo beneficio</v-list-item-title>
          </v-list-item>          
        </v-list-group>


          <v-list-item  :to="{name: 'TableDefinitions'}">
            <v-list-item-icon>
              <v-icon large color="purple darken-2">mdi-database </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Objetos BD</v-list-item-title>
          </v-list-item>



          <div>
          <v-divider class="thicker-divider"></v-divider>
          </div>

          <v-list-group  v-if="esAdmin || esSuperadmin || esUsuario">
          <v-list-item slot="activator">
          <v-list-item-content>
          <v-list-item-title>
            <v-icon large color="orange">mdi-chart-areaspline</v-icon>
            Reportes Form B
          </v-list-item-title> 
        </v-list-item-content>   
          </v-list-item>
          <v-list-item :to="{name: 'Graphic'}">
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Matriculas y puntajes</v-list-item-title>
          </v-list-item>
          <v-list-item  :to="{name: 'FormB2'}">
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Retenciones y titulaciones</v-list-item-title>
          </v-list-item>    
        </v-list-group>


        <v-list-group  v-if="esAdmin || esSuperadmin || esUsuario">
          <v-list-item slot="activator">
          <v-list-item-content>
          <v-list-item-title>
            <v-icon large color="orange">mdi-chart-areaspline</v-icon>
            Otros Reportes
          </v-list-item-title> 
        </v-list-item-content>   
          </v-list-item>
          <v-list-item :to="{name: 'Indicadores_1'}">
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Indicadores de matriculas</v-list-item-title>
          </v-list-item>
          <v-list-item  :to="{name: 'Indicadores_2'}">
            <v-list-item-icon>
              <v-icon color="#EA7900">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Indicadores de retenciones y titulaciones</v-list-item-title>
          </v-list-item>    
        </v-list-group>

          <v-divider></v-divider>

        <v-list-item :to="{name: 'Users'}" v-if="esSuperadmin">
          <v-list-item-icon>
            <v-icon large color="blue darken-2">mdi-account-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Administración de usuarios</v-list-item-title>
        </v-list-item>

        </v-list>    
      </v-navigation-drawer>

    <v-app-bar app style="background-color: #00A499">
      <v-app-bar-nav-icon @click="drawer = !drawer" v-if='logueado'></v-app-bar-nav-icon>

      <v-toolbar-title v-if='logueado' >Menú</v-toolbar-title>
      <v-spacer></v-spacer>

      
      <template v-slot:extension v-if="this.$route.name === 'InMatNoCpp' || this.$route.name === 'CppView' || this.$route.name === 'InTitNoCpp'">
        <v-tabs
          v-model="currentItem"
          fixed-tabs
          slider-color="#EA7900"
          slider-height="600px"
        >
          <v-tab
            v-for="item in items"
            :key="item"
            :to="{name: item}"
            :style="{ color: textColor }"                      >
            <span v-if="item === 'InMatNoCpp'">En Matriculas y no en CPP</span>
            <span v-else-if="item === 'CppView'">CPP</span>  
            <span v-else>En titulados y no en CPP</span>   
            <!-- <span v-else>{{ item.alias || item.name }}</span>                            -->
            <!-- {{ item }} -->
          </v-tab>
        </v-tabs>
      </template>

          <v-btn icon @click="salir" v-if='logueado'>
            <v-icon>mdi-logout</v-icon>
          </v-btn>
    </v-app-bar>


    <v-main>
      <router-view/>
    </v-main>    
  </v-app>
</template>

<script>
  export default {
    name: 'App',
    metaInfo: {
    title: 'Mi Nueva Aplicación Vue'
  },
    data: () => ({
      drawer: false,
      currentItem: 'tab-Web',
      items: [
      'CppView', 'InMatNoCpp', 'InTitNoCpp',
      ],
      textColor: '#FFFFFF'
    }),
  computed:{
    logueado(){
      return this.$store.state.email;
    },
    esSuperadmin(){
      return this.$store.state.email && this.$store.state.rol == 'Superadmin';
    },
    esAdmin(){
      return this.$store.state.email && this.$store.state.rol == 'Admin';
    },
    esUsuario(){
      return this.$store.state.email && this.$store.state.rol == 'Usuario';
    },
    email() {
      return this.$store.state.email;
    },
    rol() {
      return this.$store.state.rol;
    }
  },
  created(){
   // this.$store.dispatch("autoLogin");
  },
  methods:{
    salir(){
      this.$store.dispatch("salir");
      this.drawer = false; // Oculta el drawer al salir      
      this.$store.state.logueado = false; // Actualiza el estado de logueado a false     
      this.$router.push("/login"); // Redirige a la página de inicio de sesión al salir    
      window.location.reload(); // Actualiza la página        
    }
  }
  }
</script>