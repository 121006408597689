<template>
    <hello-world />
  </template>
  
  <script>
    import HelloWorld from '../components/VigenciaCarreraSIES'
  
    export default {
      name: 'vigencia_carrera_sies',
  
      components: {
        HelloWorld,
      },
    }
  </script>
  