<template>
    <v-layout align-start>
        <v-flex>
            <v-card-title class="text-h6 text-md-h5 text-lg-h4">ÁREA ACTUAL SIES</v-card-title>        

            <p class="font-weight-light">
      Define el área actual de la Oferta Académica
    </p>
            <v-toolbar flat color="white">
<!--                 <v-toolbar-title>Área</v-toolbar-title>
                <v-divider
                class="mx-2"
                inset
                vertical
                ></v-divider> -->
                <v-spacer></v-spacer>
                <v-text-field class="text-xs-center" v-model="search" append-icon="mdi-search-web" 
                label="Buscar Área" single-line hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                    <template  v-if="esSuperadmin || esAdmin" v-slot:activator="{ on }">
                        <v-btn color="#00A499" dark class="mb-2" v-on="on">Agregar Área</v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                        </v-card-title>            
                        <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>

                                <v-flex xs12 sm6 md6>
                                    <v-text-field v-model="CODIGO_AREA_ACTUAL_SIES" label="Id Área">
                                    </v-text-field>
                                </v-flex>                   
                                <v-flex xs12 sm6 md6>
                                    <v-text-field v-model="NOMBRE_AREA_ACTUAL_SIES" label="Área">
                                    </v-text-field>
                                </v-flex>                                                                                                                                 
                                <v-flex xs12 sm12 md12 v-show="valida">
                                    <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
  
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-container>
                        </v-card-text>            
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#00A499" flat @click="close">Cancelar</v-btn>
                        <v-btn color="#00A499" flat @click="guardar">Guardar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
               
            </v-toolbar>
            <v-data-table
                :headers="filteredHeaders"
                :items="AREA_ACTUAL_SIES"
                :search="search"
                class="elevation-1"
            >
  
  <template v-slot:[`item.acciones`]="{ item }">
          <v-icon
            small
            color="#00A499"
            class="mr-2"
            @click="editItem(item)"
          >
          mdi-pencil
          </v-icon>
          <v-icon
            small
            color="#00A499"
            class="mr-2"
            @click="deleteItem(item)"
          >
          mdi-delete
          </v-icon>         
        </template>

                <template v-slot:items="props">
              <td>{{ props.item.CODIGO_AREA_ACTUAL_SIES }}</td>
                <td>{{ props.item.NOMBRE_AREA_ACTUAL_SIES }}</td>
                </template>
                <template v-slot:no-data>
                <v-btn color="#00A499" @click="listar()">Resetear</v-btn>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
  </template>
  
  <script>
    import axios from 'axios'
    export default {
        data(){
            return{
                dialog: false,
                search:'',
                AREA_ACTUAL_SIES:[],
                headers: [
                    { text: 'Acciones', value: 'acciones', sortable: false },
                    { text: 'Id Área', value: 'CODIGO_AREA_ACTUAL_SIES', sortable: true },
                    { text: 'Área', value: 'NOMBRE_AREA_ACTUAL_SIES', sortable: true }
                ],
                editedIndex: -1,
                // id:'',
              //   tipo_persona:'Proveedor',
                CODIGO_AREA_ACTUAL_SIES: '',
                NOMBRE_AREA_ACTUAL_SIES: '',
                valida:0,
                validaMensaje:[],
              //    adModal:0,
              //   adAccion:0,
              //   adNombre:'',
              //   adId:''
            }
        },
        computed: {

            logueado(){
            return this.$store.state.email;
            },
            esSuperadmin(){
            return this.$store.state.email && this.$store.state.rol == 'Superadmin';
            },
            esAdmin(){
            return this.$store.state.email && this.$store.state.rol == 'Admin';
            },
            esUsuario(){
            return this.$store.state.email && this.$store.state.rol == 'Usuario';
            },
            filteredHeaders() {
        // Filtrar los encabezados según el rol del usuario
        return this.headers.filter(header => {
        return header.value !== 'acciones' || this.esSuperadmin || this.esAdmin;
        });
         },
            formTitle () {
            return this.editedIndex === -1 ? 'Nuevo Área' : 'Editar Área'
            }
        },
        watch: {
            dialog (val) {
            val || this.close()
            }
        },
        created () {
            this.listar()
        },
        methods: {

            listar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                axios.get('http://158.170.66.74:8080/area_actual_sies/list',configuracion).then(function (response){
                    me.AREA_ACTUAL_SIES=response.data;
                }).catch(function(error){
                    console.log(error);
                });
  
            },
            
            limpiar(){
              //  this.id='';
                this.CODIGO_AREA_ACTUAL_SIES='';
                this.NOMBRE_AREA_ACTUAL_SIES='';     
                this.valida=0;
                this.validaMensaje=[];
                this.editedIndex=-1;
            },
           validar(){
                this.valida=0;
                this.validaMensaje=[];
                if(!Number.isInteger(Number(this.CODIGO_AREA_ACTUAL_SIES))){
                  this.validaMensaje.push('Debe ingresar id válido');
              }
                if(!this.NOMBRE_AREA_ACTUAL_SIES){
                    this.validaMensaje.push('Ingrese un Área válido.');
                }
                if (this.validaMensaje.length){
                    this.valida=1;
                }
                return this.valida;
            },
            guardar(){
                let me=this;
              //   let header={"Token" : this.$store.state.token};
               //  let configuracion= {headers : header};
                if (this.validar()){
                    return;
                }
                if (this.editedIndex >-1){
                    //Código para editar
                    axios.put(`http://158.170.66.74:8080/area_actual_sies/update/${this.CODIGO_AREA_ACTUAL_SIES}`,{
                        'CODIGO_AREA_ACTUAL_SIES':this.CODIGO_AREA_ACTUAL_SIES,
                        'NOMBRE_AREA_ACTUAL_SIES':this.NOMBRE_AREA_ACTUAL_SIES,
               //     },configuracion)
                    })
                    .then(function(response){
                        me.limpiar();
                        me.close();
                        me.listar();
                    })
                    .catch(function(error){
                        console.log(error);
                    });
                }else{
                    //Código para guardar
                    axios.post('http://158.170.66.74:8080/area_actual_sies/add',{
                        'CODIGO_AREA_ACTUAL_SIES':this.CODIGO_AREA_ACTUAL_SIES,                    
                        'NOMBRE_AREA_ACTUAL_SIES':this.NOMBRE_AREA_ACTUAL_SIES                    })
                    .then(function(response){
                        me.limpiar();
                        me.close();
                        me.listar();
                    })
                    .catch(function(error){
                        console.log(error);
                    });
                }
            },
            editItem (item) {
                this.CODIGO_AREA_ACTUAL_SIES=item.CODIGO_AREA_ACTUAL_SIES;              
                this.NOMBRE_AREA_ACTUAL_SIES=item.NOMBRE_AREA_ACTUAL_SIES;
                this.dialog = true;
                this.editedIndex=1;
            },

  
            async deleteItem(item) {
  if (confirm('¿Estás seguro de que quieres eliminar este elemento?')) {
    try {
      // Hacer la petición DELETE a la API con Axios
      const respuesta = await axios.delete(`http://158.170.66.74:8080/area_actual_sies/delete/${item.CODIGO_AREA_ACTUAL_SIES}`);
      
      // Verificar si la petición fue exitosa y mostrar un mensaje al usuario
      if (respuesta.status !== 200) {
        throw new Error('Error al eliminar el elemento');
      }

      // Esperar a que la eliminación se complete antes de obtener los datos actualizados
      await this.listar();
    } catch (error) {
      console.error(error);
      alert('Ocurrió un error al eliminar el elemento');
    }
  }
},

  
            close () {
                this.dialog = false;
            }
        }
    }
  </script>
  