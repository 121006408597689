<template>
  <v-layout align-start>
      <v-flex>
        <v-card-title class="text-h6 text-md-h5 text-lg-h4">Planes que están en titulados, pero no en CPP</v-card-title>        
        <v-container>
         </v-container>
         
            <v-toolbar flat color="white">
                
              <!-- <v-divider
              class="mx-2"
              inset
              vertical
              ></v-divider> -->


              <v-spacer></v-spacer>
              
              <v-text-field class="text-xs-center" v-model="search" append-icon="mdi-search-web" 
              label="Buscar plan" single-line hide-details></v-text-field>
              
              <v-spacer></v-spacer>
              <button @click="exportToExcel">      
      <v-icon large color="#1D6F42">mdi-file-excel-box</v-icon>
      Exportar
    </button>
              <v-dialog v-model="dialog" max-width="500px">
                  <v-card>
                      <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                      </v-card-title>            
                      <v-card-text>
                      <v-container grid-list-md>
                          <v-layout wrap>
                              <v-flex xs12 sm6 md6>
                                  <v-text-field v-model="COD_PLAN" label="Código de Plan">
                                  </v-text-field>
                              </v-flex>                   
                              <v-flex xs12 sm6 md6>
                                  <v-text-field v-model="NOMBRE_PLAN" label="Nombre de Plan">
                                  </v-text-field>
                              </v-flex>                   
               
                              <v-flex xs12 sm12 md12 v-show="valida">
                                  <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">

                                  </div>
                              </v-flex>
                          </v-layout>
                      </v-container>
                      </v-card-text>            

                  </v-card>
              </v-dialog>
          </v-toolbar>
          <v-data-table
              :headers="headers"
              :items="planes"
              :search="search"
              class="elevation-1"
          >

              <template v-slot:items="props">
              <td>{{ props.item.COD_PLAN }}</td>
              <td>{{ props.item.NOMBRE_PLAN }}</td>
              </template>
              <template v-slot:no-data>
              <v-btn color="#00A499" @click="listar_views()">Recargar</v-btn>
              </template>
          </v-data-table>
      </v-flex>
  </v-layout>
</template>

<script>
  import axios from 'axios'
  import ExcelJS from 'exceljs';

  export default {
      data(){
          return{
            showFilteredData: false,
            isDuplicating: false,
      snackbar: {
        show: false,
        color: '',
        message: '',
        timeout: 3000
      },
      hasSI: false, // Variable para verificar si hay valores "SI"
              showFilteredData: false,
              dialog: false,
              search:'',
              planes:[],
              headers: [
                  { text: 'Código de Plan', value: 'COD_PLAN', sortable: true },
                  { text: 'Nombre de Plan', value: 'NOMBRE_PLAN', sortable: false  },
              ],
              editedIndex: -1,
              id:'',
              COD_PLAN:'',
            //   tipo_persona:'Proveedor',
              NOMBRE_PLAN:'',
              valida:0,
              validaMensaje:[],
            //    adModal:0,
            //   adAccion:0,
            //   adNombre:'',
            //   adId:''
          }
      },
      computed: {
          formTitle () {
          return this.editedIndex === -1 ? 'Nuevo registro' : 'Editar registro'
          },
      },
      watch: {
          dialog (val) {
          val || this.close()
          }
      },
      created () {
          this.listar_views()
      },
      methods: {
        exportToExcel() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Datos');

      // Agrega los encabezados a la hoja de cálculo
      const headerRow = worksheet.addRow(this.headers.map(header => header.text));
      headerRow.font = { bold: true };

      // Agrega los datos de la tabla a la hoja de cálculo
      this.planes .forEach(item => {
        const rowData = [];
        this.headers.forEach(header => {
          rowData.push(item[header.value]);
        });
        worksheet.addRow(rowData);
      });

      // Ajusta el ancho de las columnas
      worksheet.columns.forEach(column => {
        column.width = 15;
      });

      // Genera un Blob a partir del archivo de Excel
      workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Genera una URL para descargar el archivo
        const url = URL.createObjectURL(blob);

        // Crea un enlace para descargar el archivo
        const link = document.createElement('a');
        link.href = url;
        link.download = 'tabla.xlsx';
        link.click();

        // Libera la URL creada
        URL.revokeObjectURL(url);
      });
    },
          listar_views(){
              let me=this;
              let header={"Token" : this.$store.state.token};
              let configuracion= {headers : header};
              axios.get('http://158.170.66.74:8080/noencpp/listEnTit',configuracion).then(function (response){
                  me.planes=response.data;
              }).catch(function(error){
                  console.log(error);
              });

          },
          close () {
              this.dialog = false;
          }
      }
  }
</script>
