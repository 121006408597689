<template>
    <v-layout align-start>
        <v-flex>
            <v-card-title class="text-h6 text-md-h5 text-lg-h4">INGRESO PRIMER AÑO</v-card-title>        

            <p class="font-weight-light">
      Define en base a las vías de ingreso del estudiante, su calidad de ingreso de primer año a la carrera.
    </p>
            <v-toolbar flat color="white">

                <v-spacer></v-spacer>
                <v-text-field class="text-xs-center" v-model="search" append-icon="mdi-search-web" 
                label="Buscar Categoría" single-line hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                    <template  v-if="esSuperadmin || esAdmin" v-slot:activator="{ on }">
                        <v-btn color="#00A499" dark class="mb-2" v-on="on">Agregar Categoria</v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                        </v-card-title>            
                        <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>

                                <v-flex xs12 sm6 md6>
                                    <v-text-field v-model="COD_VIA_INGRESO" label="Código de vía de ingreso">
                                    </v-text-field>
                                </v-flex>                   
                                <v-flex xs12 sm6 md6>
                                    <v-text-field v-model="VIA_INGRESO" label="Vía de ingreso">
                                    </v-text-field>
                                </v-flex>     
                                <v-flex xs12 sm6 md6>
                                  <v-select v-model="ING_PRIMER_ANHO_FORMB"
                                  :items="ing_primer_anho_formb" label="Ing. Primer Año FORMB">
                                  </v-select>
                              </v-flex>                  
                              <v-flex xs12 sm6 md6>
                                  <v-select v-model="ING_PRIMER_ANHO_FICHA"
                                  :items="ing_primer_anho_ficha" label="Ing. Primer Año FICHA">
                                  </v-select>
                              </v-flex>     
                                <v-flex xs12 sm6 md6>
                                    <v-text-field v-model="TIPO_ACCESO_FORM_B" label="Tipo Acceso FORM">
                                    </v-text-field>
                                </v-flex>                                                                                                                                                                                                                                                                                      
                                <v-flex xs12 sm12 md12 v-show="valida">
                                    <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
  
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-container>
                        </v-card-text>            
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#00A499" flat @click="close">Cancelar</v-btn>
                        <v-btn color="#00A499" flat @click="guardar">Guardar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
               
            </v-toolbar>
            <v-data-table
                :headers="filteredHeaders"
                :items="ING_PRIMER_ANHO"
                :search="search"
                class="elevation-1"
            >
  
  <template v-slot:[`item.acciones`]="{ item }">
          <v-icon
            small
            color="#00A499"
            class="mr-2"
            @click="editItem(item)"
          >
          mdi-pencil
          </v-icon>
          <v-icon
            small
            color="#00A499"
            class="mr-2"
            @click="deleteItem(item)"
          >
          mdi-delete
          </v-icon>         
        </template>

                <template v-slot:items="props">
              <td>{{ props.item.COD_VIA_INGRESO }}</td>
                <td>{{ props.item.VIA_INGRESO }}</td>
                <td>{{ props.item.ING_PRIMER_ANHO_FORMB }}</td>
                <td>{{ props.item.ING_PRIMER_ANHO_FICHA }}</td>
                <td>{{ props.item.TIPO_ACCESO_FORM_B }}</td>
                </template>
                <template v-slot:no-data>
                <v-btn color="#00A499" @click="listar()">Resetear</v-btn>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
  </template>
  
  <script>
    import axios from 'axios'
    export default {
        data(){
            return{
                dialog: false,
                search:'',
                ING_PRIMER_ANHO:[],
                headers: [
                    { text: 'Acciones', value: 'acciones', sortable: false },
                    { text: 'Código de vía de ingreso', value: 'COD_VIA_INGRESO', sortable: true },
                    { text: 'Vía de ingreso', value: 'VIA_INGRESO', sortable: true },
                    { text: 'Ingreso Primer Año FORMB', value: 'ING_PRIMER_ANHO_FORMB', sortable: true },
                    { text: 'Ingreso Primer Año Ficha Acr.', value: 'ING_PRIMER_ANHO_FICHA', sortable: true },
                    { text: 'Tipo Acceso FORMB', value: 'TIPO_ACCESO_FORM_B', sortable: true }
                ],                             
                editedIndex: -1,
                ing_primer_anho_formb: ['0','1'],
                ing_primer_anho_ficha: ['0','1'],
                COD_VIA_INGRESO: '',
                VIA_INGRESO: '',
                ING_PRIMER_ANHO_FORMB: '',
                ING_PRIMER_ANHO_FICHA: '',          
                TIPO_ACCESO_FORM_B: '',                                      
                valida:0,
                validaMensaje:[],
            }
        },
        computed: {

            logueado(){
            return this.$store.state.email;
            },
            esSuperadmin(){
            return this.$store.state.email && this.$store.state.rol == 'Superadmin';
            },
            esAdmin(){
            return this.$store.state.email && this.$store.state.rol == 'Admin';
            },
            esUsuario(){
            return this.$store.state.email && this.$store.state.rol == 'Usuario';
            },
            filteredHeaders() {
        // Filtrar los encabezados según el rol del usuario
        return this.headers.filter(header => {
        return header.value !== 'acciones' || this.esSuperadmin || this.esAdmin;
        });
      },
            formTitle () {
            return this.editedIndex === -1 ? 'Nueva Categoría' : 'Editar Categoría'
            }
        },
        watch: {
            dialog (val) {
            val || this.close()
            }
        },
        created () {
            this.listar()
        },
        methods: {

            listar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                axios.get('http://LOCALHOST:4000/ing_primer_anho/listIng',configuracion).then(function (response){
                    me.ING_PRIMER_ANHO=response.data;
                }).catch(function(error){
                    console.log(error);
                });
  
            },
            
            limpiar(){
              //  this.id='';
                this.COD_VIA_INGRESO='';
                this.VIA_INGRESO='';     
                this.ING_PRIMER_ANHO_FORMB='';
                this.ING_PRIMER_ANHO_FICHA='';           
                this.TIPO_ACCESO_FORM_B='';                                         
                this.valida=0;
                this.validaMensaje=[];
                this.editedIndex=-1;
            },
           validar(){
                this.valida=0;
                this.validaMensaje=[];
                if(!Number.isInteger(Number(this.COD_VIA_INGRESO))){
                  this.validaMensaje.push('Debe ingresar id válido');
              }
                if(!this.VIA_INGRESO){
                    this.validaMensaje.push('Ingrese una vía válida.');
                }
                if(!Number.isInteger(Number(this.ING_PRIMER_ANHO_FORMB))){
                  this.validaMensaje.push('Debe ingresar un valor válido');
              }      
              if(!Number.isInteger(Number(this.ING_PRIMER_ANHO_FICHA))){
                  this.validaMensaje.push('Debe ingresar un valor válido');
              }
              if(!this.TIPO_ACCESO_FORM_B){
                    this.validaMensaje.push('Ingrese un tipo válido.');
                }              
                if (this.validaMensaje.length){
                    this.valida=1;
                }
                return this.valida;
            },
            guardar(){
                let me=this;
              //   let header={"Token" : this.$store.state.token};
               //  let configuracion= {headers : header};
                if (this.validar()){
                    return;
                }
                if (this.editedIndex >-1){
                    //Código para editar
                    axios.put(`http://LOCALHOST:4000/ing_primer_anho/update/${this.COD_VIA_INGRESO}`,{
                        'COD_VIA_INGRESO':this.COD_VIA_INGRESO,
                        'VIA_INGRESO':this.VIA_INGRESO,
                        'ING_PRIMER_ANHO_FORMB':this.ING_PRIMER_ANHO_FORMB,
                        'ING_PRIMER_ANHO_FICHA':this.ING_PRIMER_ANHO_FICHA,
                        'TIPO_ACCESO_FORM_B':this.TIPO_ACCESO_FORM_B,
               //     },configuracion)
                    })
                    .then(function(response){
                        me.limpiar();
                        me.close();
                        me.listar();
                    })
                    .catch(function(error){
                        console.log(error);
                    });
                }else{
                    //Código para guardar
                    axios.post('http://LOCALHOST:4000/ing_primer_anho/add',{
                        'COD_VIA_INGRESO':this.COD_VIA_INGRESO,
                        'VIA_INGRESO':this.VIA_INGRESO,
                        'ING_PRIMER_ANHO_FORMB':this.ING_PRIMER_ANHO_FORMB,
                        'ING_PRIMER_ANHO_FICHA':this.ING_PRIMER_ANHO_FICHA,
                        'TIPO_ACCESO_FORM_B':this.TIPO_ACCESO_FORM_B               })
                    .then(function(response){
                        me.limpiar();
                        me.close();
                        me.listar();
                    })
                    .catch(function(error){
                        console.log(error);
                    });
                }
            },
          async  editItem (item) {
                
                this.COD_VIA_INGRESO=item.COD_VIA_INGRESO;              
                this.VIA_INGRESO=item.VIA_INGRESO;
                this.ING_PRIMER_ANHO_FORMB=item.ING_PRIMER_ANHO_FORMB;
                this.ING_PRIMER_ANHO_FICHA=item.ING_PRIMER_ANHO_FICHA;
                this.TIPO_ACCESO_FORM_B=item.TIPO_ACCESO_FORM_B;
                this.dialog = true;
                this.editedIndex=1;

                try {
      const response = await axios.get(`http://LOCALHOST:4000/ing_primer_anho/${item.COD_VIA_INGRESO}`);
      this.ING_PRIMER_ANHO_FORMB = response.data.ING_PRIMER_ANHO_FORMB;
    } catch (error) {
      console.error(error);
    }

            },

  
            async deleteItem(item) {
  if (confirm('¿Estás seguro de que quieres eliminar este elemento?')) {
    try {
      // Hacer la petición DELETE a la API con Axios
      const respuesta = await axios.delete(`http://LOCALHOST:4000/ing_primer_anho/delete/${item.COD_VIA_INGRESO}`);
      
      // Verificar si la petición fue exitosa y mostrar un mensaje al usuario
      if (respuesta.status !== 200) {
        throw new Error('Error al eliminar el elemento');
      }

      // Esperar a que la eliminación se complete antes de obtener los datos actualizados
      await this.listar();
    } catch (error) {
      console.error(error);
      alert('Ocurrió un error al eliminar el elemento');
    }
  }
},

  
            close () {
                this.dialog = false;
            }
        }
    }
  </script>
  