<template>
  <div class="row mt-3">
    <div class="col-md-6 offset-md-3">
      <div class="card border border-dark">
        <div class="card-header bg-dark">
          <div class="card-body">
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-select
                    v-model="selectedYearFrom"
                    :items="uniqueYears"
                    label="Año Desde"
                    outlined
                    @change="fetchData"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="selectedYearTo"
                    :items="uniqueYears"
                    label="Año Hasta"
                    outlined
                    @change="fetchData"
                  ></v-select>
                </v-col>
              </v-row>


              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="selectedNivelGlobal"
                    :items="['Todos', ...uniqueNivGlobal]"
                    label="Nivel Global"
                    outlined
                    @change="handleNivelGlobalChange"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="selectedUMayor"
                    :items="['Todos', ...uniqueUMayor]"
                    label="Unidad Mayor"
                    outlined
                    @change="handleUmayorChange"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="selectedUMenor"
                    :items="filteredUMenor"
                    label="Unidad Menor"
                    outlined
                    :disabled="!selectedUMayor || selectedUMayor === 'Todos'"
                    @change="handleUmenorChange"
                  ></v-autocomplete>
                </v-col>
              </v-row>
          

              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="selectedCarrera"
                    :items="filteredCarrera"
                    label="Carrera"
                    outlined
                    @change="handleCarreraChange"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="selectedCodigoSIES"
                    :items="filteredCodigoSIES"
                    label="Código SIES"
                    outlined
                    :disabled="!selectedCarrera || selectedCarrera === 'Todos'"
                    @change="handleSIESChange"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="selectedPlan"
                    :items="filteredPlanes"
                    label="Plan"
                    outlined
                    :disabled="!selectedCarrera || selectedCarrera === 'Todos' || !selectedCodigoSIES"
                    @change="handlePlanChange"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="selectedCodigoPLAN"
                    :items="filteredCodigoPLAN"
                    label="Código Plan"
                    outlined
                    :disabled="!selectedCarrera || selectedCarrera === 'Todos' || !selectedCodigoSIES || !selectedPlan"
                    @change="fetchData"
                  ></v-select>
                </v-col>
              </v-row>

              <v-card-title class="text-h6 text-md-h5 text-lg-h4">MATRICULAS TOTALES Y DE PRIMER AÑO</v-card-title>        

              <canvas id="bar-chart"></canvas>

              <v-data-table
                ref="dataTable"
                :headers="tableHeaders"
                :items="filteredRutCountTable"
                item-key="ANHO"
                :rows-per-page-items="[10, 25, 50, 100]"
                class="mt-3"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.ANHO }}</td>
                    <td>{{ item.NOMBRE_JORNADA }}</td>
                    <td>{{ item.CODIGO_PROGRAMA_SIES }}</td>
                    <td>{{ item.NOMBRE_CARRERA }}</td>
                    <td>{{ item.NOMBRE_TIPO_CARRERA_SIES }}</td>
                    <td>{{ item.VIA_INGRESO }}</td>
                    <td>{{ item.SEXO }}</td>
                    <td>{{ item.VIGENCIA }}</td>
                    <td>{{ item.COHORTE }}</td>
                    <td>{{ item.MATRICULADOS }}</td>

                 <!--   <td>{{ item.COUNT }}</td>-->
                  </tr>
                </template>
              </v-data-table>

              <v-card-title class="text-h6 text-md-h5 text-lg-h4">CARACTERIZACIÓN DE ESTUDIANTES</v-card-title>        


              <v-data-table
                  :headers="promedioHeaders"
                  :items="filteredPromediosTable"
                  item-key="ANHO"
                  :rows-per-page-items="[10, 25, 50, 100]"
                  class="mt-3"
                 >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.ANHO }}</td>
                      <td>{{ item.NOMBRE_JORNADA }}</td>
                      <td>{{ item.CODIGO_PROGRAMA_SIES }}</td>
                      <td>{{ item.NOMBRE_CARRERA }}</td>
                      <td>{{ item.NOMBRE_TIPO_CARRERA_SIES }}</td>
                      <td>{{ item.SEXO }}</td>
                      <td>{{ item.VIGENCIA }}</td>
                      <td>{{ item.PPLM }}</td>
                      <td>{{ item.PMX }}</td>
                      <td>{{ item.PMI }}</td>
                      <td>{{ item.DE }}</td>
                    </tr>
                  </template>
                </v-data-table>
              <v-btn class="mt-3" color="primary" @click="exportarExcel">Exportar a Excel</v-btn>


              <template>
          <div>
            <!-- Pantalla de espera -->
            <div v-if="isLoading" class="loading-screen">
              <div class="loading-overlay">
                <div class="loading-spinner"></div>
                <p>Cargando...</p>
              </div>
            </div>

            <!-- Resto de tu contenido -->
          </div>
        </template>

            </v-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Bar } from 'vue-chartjs';
import Chart from 'chart.js/auto';
import ExcelJS from 'exceljs';

export default {
  components: {
    Bar
  },
  data() {
    return {
      chartData: null,
      chartOptions: {
        responsive: true
      },      
      selectedYearFrom: '',
      selectedNivelGlobal: '',
      selectedUMayor: '',
      selectedUMenor: '',
      selectedYearTo: '',
      selectedCarrera: '',
      selectedPlan: '',
      selectedCodigoSIES: '',
      selectedCodigoPLAN: '',
      data: [],
      tableHeaders: [
        { text: 'Año', value: 'ANHO' },
        { text: 'Jornada', value: 'NOMBRE_JORNADA' },
        { text: 'Código Programa SIES', value: 'CODIGO_PROGRAMA_SIES' },
        { text: 'Carrera', value: 'NOMBRE_CARRERA' },
        { text: 'Tipo Carrera SIES', value: 'NOMBRE_TIPO_CARRERA_SIES' },
        { text: 'Vía de Ingreso', value: 'VIA_INGRESO' },
        { text: 'Sexo', value: 'SEXO' },
        { text: 'Vigencia', value: 'VIGENCIA' },

        { text: 'Cohorte', value: 'COHORTE' },
        { text: 'Matriculados', value: 'MATRICULADOS' },

      //  { text: 'Total', value: 'COUNT' },

      ],
      promedioHeaders:[
        { text: 'Año', value: 'ANHO' },
        { text: 'Jornada', value: 'NOMBRE_JORNADA' },
        { text: 'Código Programa SIES', value: 'CODIGO_PROGRAMA_SIES' },
        { text: 'Carrera', value: 'NOMBRE_CARRERA' },
        { text: 'Nombre Tipo Carrera SIES', value: 'NOMBRE_TIPO_CARRERA_SIES' },
        { text: 'Sexo', value: 'SEXO' },
        { text: 'Vigencia', value: 'VIGENCIA' },

        { text: 'Promedio PPLM', value: 'PPLM' },
        { text: 'Promedio PMX', value: 'PMX' },
        { text: 'Promedio PMI', value: 'PMI' },
        { text: 'Promedio DE', value: 'DE' }
      ],
      isLoading: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    uniqueYears() {
      const sortedYears = [...new Set(this.data.map(item => item.ANHO))].sort((a, b) => a - b);
      return sortedYears;

      
    },
    uniqueCarreras() {
      const uniqueCarreras = [...new Set(this.data.map(item => item.NOMBRE_CARRERA))];
      uniqueCarreras.sort(); // Ordenar los valores de NOMBRE_CARRERA alfabéticamente
      return uniqueCarreras;
    },
    uniqueNivGlobal() {
      const uniqueNivGlobal = [...new Set(this.data.map(item => item.NOMBRE_NIVEL_GLOBAL_SIES))];
      uniqueNivGlobal.sort(); // Ordenar los valores de NOMBRE_NIVEL_GLOBAL_SIES alfabéticamente
      return uniqueNivGlobal;
    },
    uniqueUMayor() {
      const uniqueUMayor = [...new Set(this.data.map(item => item.NOMBRE_UNIDAD_MAYOR))];
      uniqueUMayor.sort(); // Ordenar los valores de NOMBRE_UNIDAD_MAYOR alfabéticamente
      return uniqueUMayor;
    },
    uniqueUMenor() {
      const uniqueUMenor = [...new Set(this.data.map(item => item.NOMBRE_UNIDAD_MAYOR))];
      uniqueUMenor.sort(); // Ordenar los valores de NOMBRE_UNIDAD_MENOR alfabéticamente
      return uniqueUMenor;
    },
    filteredPlanes() {
      if (this.selectedCodigoSIES === 'Todos') {
        return [...new Set(this.data.map(item => item.PLAN))];
      } else {
        return [...new Set(this.data
          .filter(item => item.CODIGO_PROGRAMA_SIES === this.selectedCodigoSIES)
          .map(item => item.PLAN)
        )];
      }
    },
    filteredCodigoSIES() {
      let filteredData = this.data;
      if (this.selectedCarrera && this.selectedCarrera !== 'Todos') {
        filteredData = filteredData.filter(item => item.NOMBRE_CARRERA === this.selectedCarrera);
      }

      return [...new Set(filteredData.map(item => item.CODIGO_PROGRAMA_SIES))];
    },
    filteredCarrera() {
      let filteredData = this.data;
      if (this.selectedNivelGlobal && this.selectedNivelGlobal !== 'Todos') {
        filteredData = filteredData.filter(item => item.NOMBRE_NIVEL_GLOBAL_SIES === this.selectedNivelGlobal);
      }
        // Filtrar por NOMBRE_UNIDAD_MAYOR si está seleccionado y no es "Todos"
  if (this.selectedUMayor && this.selectedUMayor !== 'Todos') {
    filteredData = filteredData.filter(item => item.NOMBRE_UNIDAD_MAYOR === this.selectedUMayor);
  }
          // Filtrar por NOMBRE_UNIDAD_MAYOR si está seleccionado y no es "Todos"
          if (this.selectedUMenor && this.selectedUMenor !== 'Todos') {
    filteredData = filteredData.filter(item => item.NOMBRE_UNIDAD_MENOR === this.selectedUMenor);
  }

      return [...new Set(filteredData.map(item => item.NOMBRE_CARRERA))];
    },
    filteredUMenor() {
      let filteredData = this.data;
      if (this.selectedUMayor && this.selectedUMayor !== 'Todos') {
        filteredData = filteredData.filter(item => item.NOMBRE_UNIDAD_MAYOR === this.selectedUMayor);
      }

      return [...new Set(filteredData.map(item => item.NOMBRE_UNIDAD_MENOR))];
    },
    
    filteredCodigoPLAN() {
      let filteredData = this.data;
      if (this.selectedCarrera && this.selectedCarrera !== 'Todos') {
        filteredData = filteredData.filter(item => item.NOMBRE_CARRERA === this.selectedCarrera);
      }
      if (this.selectedPlan && this.selectedPlan !== 'Todos') {
        filteredData = filteredData.filter(item => item.PLAN === this.selectedPlan);
      }
      return [...new Set(filteredData.map(item => item.COD_PLAN))];
    },
    filteredRutCountTable() {
      let filteredData = this.data;
      if (this.selectedYearFrom) {
        filteredData = filteredData.filter(item => item.ANHO >= this.selectedYearFrom);
      }
      if (this.selectedYearTo) {
        filteredData = filteredData.filter(item => item.ANHO <= this.selectedYearTo);
      }
      if (this.selectedNivelGlobal) {
        filteredData = filteredData.filter(item => item.NOMBRE_NIVEL_GLOBAL_SIES <= this.selectedNivelGlobal);
      }
      if (this.selectedUMayor) {
        filteredData = filteredData.filter(item => item.NOMBRE_UNIDAD_MAYOR <= this.selectedUMayor);
      }
      if (this.selectedUMenor && this.selectedUMenor !== 'Todos') {
        filteredData = filteredData.filter(item => item.NOMBRE_UNIDAD_MENOR === this.selectedUMenor);
      }
      if (this.selectedCarrera && this.selectedCarrera !== 'Todos') {
        filteredData = filteredData.filter(item => item.NOMBRE_CARRERA === this.selectedCarrera);
      }
      if (this.selectedPlan && this.selectedPlan !== 'Todos') {
        filteredData = filteredData.filter(item => item.PLAN === this.selectedPlan);
      }
      if (this.selectedCodigoSIES && this.selectedCodigoSIES !== 'Todos') {
        filteredData = filteredData.filter(item => item.CODIGO_PROGRAMA_SIES === this.selectedCodigoSIES);
      }
      if (this.selectedCodigoPLAN && this.selectedCodigoPLAN !== 'Todos') {
        filteredData = filteredData.filter(item => item.COD_PLAN === this.selectedCodigoPLAN);
      }

      
      const rutCounts = new Map();
      filteredData.forEach((row) => {
        const ANHO = row.ANHO;
        const CODIGO_PROGRAMA_SIES = row.CODIGO_PROGRAMA_SIES;
        const NOMBRE_CARRERA = row.NOMBRE_CARRERA;
        const VIA_INGRESO = row.VIA_INGRESO;
        const NOMBRE_JORNADA = row.NOMBRE_JORNADA;
        const NOMBRE_TIPO_CARRERA_SIES = row.NOMBRE_TIPO_CARRERA_SIES;
        const SEXO = row.SEXO;
        const VIGENCIA = row.VIGENCIA;

        const COHORTE = row.COHORTE;
        const MATRICULADOS = row.MATRICULADOS;

        const key = `${CODIGO_PROGRAMA_SIES}_${NOMBRE_CARRERA}_${VIA_INGRESO}_${SEXO}_${VIGENCIA}_${COHORTE}_${MATRICULADOS}_${NOMBRE_JORNADA}_${NOMBRE_TIPO_CARRERA_SIES}_${ANHO}`;
        if (rutCounts.has(key)) {
          rutCounts.set(key, rutCounts.get(key) + 1);
        } else {
          rutCounts.set(key, 1);
        }
      });

      const tableData = [];
      for (const [key, value] of rutCounts.entries()) {
        const [CODIGO_PROGRAMA_SIES,NOMBRE_CARRERA, VIA_INGRESO,SEXO,VIGENCIA,COHORTE,MATRICULADOS, NOMBRE_JORNADA, NOMBRE_TIPO_CARRERA_SIES, ANHO] = key.split('_');
        tableData.push({
          CODIGO_PROGRAMA_SIES,
          NOMBRE_CARRERA,
          VIA_INGRESO,
          SEXO,
          VIGENCIA,
          NOMBRE_JORNADA,
          NOMBRE_TIPO_CARRERA_SIES,
          ANHO,
          COHORTE,
          MATRICULADOS,
          COUNT: value
        });
      }

      return tableData;
    },
    
    filteredPromediosTable() {
      let filteredData = this.data;
      if (this.selectedYearFrom) {
        filteredData = filteredData.filter(item => item.ANHO >= this.selectedYearFrom);
      }
      if (this.selectedYearTo) {
        filteredData = filteredData.filter(item => item.ANHO <= this.selectedYearTo);
      }
      if (this.selectedNivelGlobal) {
        filteredData = filteredData.filter(item => item.NOMBRE_NIVEL_GLOBAL_SIES <= this.selectedNivelGlobal);
      }
      if (this.selectedUMayor) {
        filteredData = filteredData.filter(item => item.NOMBRE_UNIDAD_MAYOR <= this.selectedUMayor);
      }
      if (this.selectedUMenor && this.selectedUMenor !== 'Todos') {
        filteredData = filteredData.filter(item => item.NOMBRE_UNIDAD_MENOR === this.selectedUMenor);
      }
      if (this.selectedCarrera && this.selectedCarrera !== 'Todos') {
        filteredData = filteredData.filter(item => item.NOMBRE_CARRERA === this.selectedCarrera);
      }
      if (this.selectedPlan && this.selectedPlan !== 'Todos') {
        filteredData = filteredData.filter(item => item.PLAN === this.selectedPlan);
      }
      if (this.selectedCodigoSIES && this.selectedCodigoSIES !== 'Todos') {
        filteredData = filteredData.filter(item => item.CODIGO_PROGRAMA_SIES === this.selectedCodigoSIES);
      }
      if (this.selectedCodigoPLAN && this.selectedCodigoPLAN !== 'Todos') {
        filteredData = filteredData.filter(item => item.COD_PLAN === this.selectedCodigoPLAN);
      }
      
      const promedios = new Map();
      const counts = new Map();

      filteredData.forEach((row) => {
        const ANHO = row.ANHO;
        const NOMBRE_JORNADA = row.NOMBRE_JORNADA;
        const CODIGO_PROGRAMA_SIES = row.CODIGO_PROGRAMA_SIES;
        const NOMBRE_TIPO_CARRERA_SIES = row.NOMBRE_TIPO_CARRERA_SIES;
        const SEXO = row.SEXO;
        const VIGENCIA = row.VIGENCIA;
        const PPLM = row.PPLM;
        const PMX = row.PMX;
        const PMI = row.PMI;
        const DE = row.DE;
        const key = `${ANHO}_${NOMBRE_JORNADA}_${CODIGO_PROGRAMA_SIES}_${NOMBRE_TIPO_CARRERA_SIES}_${SEXO}_${VIGENCIA}`;
        
        if (promedios.has(key)) {
          promedios.set(key, {
            PPLM: promedios.get(key).PPLM + PPLM,
            PMX: promedios.get(key).PMX + PMX,
            PMI: promedios.get(key).PMI + PMI,
            DE: promedios.get(key).DE + DE
          });
          counts.set(key, counts.get(key) + 1);
        } else {
          promedios.set(key, {
            PPLM,
            PMX,
            PMI,
            DE
          });
          counts.set(key, 1);
        }
      });

      const tableData = [];
      for (const [key, value] of promedios.entries()) {
        const [ANHO, NOMBRE_JORNADA, CODIGO_PROGRAMA_SIES, NOMBRE_TIPO_CARRERA_SIES, SEXO] = key.split('_');
        const count = counts.get(key);
        const promedioPPLM = value.PPLM / count;
        const promedioPMX = value.PMX / count;
        const promedioPMI = value.PMI / count;
        const promedioDE = value.DE / count;
        
        tableData.push({
          ANHO,
          NOMBRE_JORNADA,
          CODIGO_PROGRAMA_SIES,
          NOMBRE_TIPO_CARRERA_SIES,
          SEXO,
          PPLM: promedioPPLM.toFixed(2),
          PMX: promedioPMX.toFixed(2),
          PMI: promedioPMI.toFixed(2),
          DE: promedioDE.toFixed(2)
        });
      }

      return tableData;
    }
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      try {
        const response = await axios.get('http://localhost:4000/indicadores/listMat');
        
        this.data = response.data;
        let filteredData = this.data;
      if (this.selectedYearFrom) {
        filteredData = filteredData.filter(item => item.ANHO >= this.selectedYearFrom);
      }
      if (this.selectedYearTo) {
        filteredData = filteredData.filter(item => item.ANHO <= this.selectedYearTo);
      }
      if (this.selectedNivelGlobal) {
        filteredData = filteredData.filter(item => item.NOMBRE_NIVEL_GLOBAL_SIES <= this.selectedNivelGlobal);
      }
      if (this.selectedUMayor) {
        filteredData = filteredData.filter(item => item.NOMBRE_UNIDAD_MAYOR <= this.selectedUMayor);
      }
      if (this.selectedUMenor && this.selectedCarrera !== 'Todos') {
        filteredData = filteredData.filter(item => item.NOMBRE_UNIDAD_MENOR === this.selectedUMenor);
      }
      if (this.selectedCarrera && this.selectedCarrera !== 'Todos') {
        filteredData = filteredData.filter(item => item.NOMBRE_CARRERA === this.selectedCarrera);
      }
      if (this.selectedPlan && this.selectedPlan !== 'Todos') {
        filteredData = filteredData.filter(item => item.PLAN === this.selectedPlan);
      }
      if (this.selectedCodigoSIES && this.selectedCodigoSIES !== 'Todos') {
        filteredData = filteredData.filter(item => item.CODIGO_PROGRAMA_SIES === this.selectedCodigoSIES);
      }
      if (this.selectedCodigoPLAN && this.selectedCodigoPLAN !== 'Todos') {
        filteredData = filteredData.filter(item => item.COD_PLAN === this.selectedCodigoPLAN);
      }
        const rutCounts = new Map();
        const parAverages = new Map();
        const cteSum = new Map();
        const matSum = new Map();
        const vacAverages = new Map();

        filteredData.forEach((row) => {
          const ANHO = row.ANHO;
       //   const RUT = row.RUT;
          const PAR = row.PAR;
          const COHORTE = row.COHORTE;
          const MATRICULADOS = row.MATRICULADOS;
          const VAC = row.VACANTES;

          if (rutCounts.has(ANHO)) {
            rutCounts.set(ANHO, rutCounts.get(ANHO) + 1);
            parAverages.set(ANHO, parAverages.get(ANHO) + PAR);
            cteSum.set(ANHO, cteSum.get(ANHO) + COHORTE);
            matSum.set(ANHO, matSum.get(ANHO) + MATRICULADOS);
            vacAverages.set(ANHO, vacAverages.get(ANHO) + VAC);

          } else {
            rutCounts.set(ANHO, 1);
            cteSum.set(ANHO, COHORTE);
            matSum.set(ANHO, MATRICULADOS);
            parAverages.set(ANHO, PAR);
            vacAverages.set(ANHO, VAC);

          }
        });



        const sortedLabels = Array.from(rutCounts.keys()).sort((a, b) => a - b);

        this.chartData = {
  labels: sortedLabels,
  datasets: [
    {
      label: 'COHORTE',
      data: sortedLabels.map(label => cteSum.get(label)),
      backgroundColor: 'rgba(54, 162, 235, 0.7)'
    },
    {
      label: 'MATRICULADOS',
      data: sortedLabels.map(label => matSum.get(label)),
      backgroundColor: 'rgba(144, 238, 144, 1 )'
    },
    this.selectedCarrera
      ? {
          label: 'PAR',
          data: sortedLabels.map(label => parAverages.get(label) / rutCounts.get(label)),
          backgroundColor: 'rgba(252, 125, 7, 0.7)'
        }
      : null, // Si no se cumple la condición, establecemos el valor como null
    this.selectedCarrera
      ? {
          label: 'VACANTES',
          data: sortedLabels.map(label => vacAverages.get(label) / rutCounts.get(label)),
          backgroundColor: 'rgba(7, 21, 252, 0.7)'
        }
      : null // Si no se cumple la condición, establecemos el valor como null
  ].filter(dataset => dataset !== null) // Filtramos los datasets nulos
};

        if (this.chart) {
          this.chart.destroy();
        }

        const ctx = document.getElementById('bar-chart').getContext('2d');
        this.chart = new Chart(ctx, {
          type: 'bar',
          data: this.chartData,
          options: this.chartOptions
        });
    
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    handleCarreraChange() {
      if (this.selectedCarrera === 'Todos') {
        this.selectedPlan = '';
        this.selectedCodigoSIES = '';
        this.selectedCodigoPLAN = '';
      }
      this.fetchData();
    },
    handleSIESChange() {
      this.selectedPlan = '';
      this.selectedCodigoPLAN = '';
      this.fetchData();
    },
    handleUmayorChange() {
      this.selectedPlan = '';
      this.selectedCodigoPLAN = '';
      this.selectedCodigoSIES = '';
      this.selectedUMenor = '';
      this.selectedCarrera = '';
  //    this.selectedNivelGlobal = '';
      this.fetchData();
    },
    handleUmenorChange() {
      this.selectedPlan = '';
      this.selectedCodigoPLAN = '';
      this.selectedCodigoSIES = '';
      this.selectedCarrera = '';
//      this.selectedNivelGlobal = '';
      this.fetchData();
    },
    handleNivelGlobalChange() {
      this.selectedPlan = '';
      this.selectedCodigoPLAN = '';
      this.selectedCodigoSIES = '';
      this.selectedCarrera = '';
      this.fetchData();
    },
    handlePlanChange() {
      if (this.selectedPlan === 'Todos') {
        this.selectedCodigoPLAN = '';
      }
      this.fetchData();
    },
    exportarExcel() {
  // Crear un nuevo workbook de Excel
  const workbook = new ExcelJS.Workbook();

  // Crear una nueva hoja de cálculo para la tabla
  const worksheetTable = workbook.addWorksheet('Detalle de ingresos');

  // Agregar los encabezados de las columnas de la tabla
  const headers = this.tableHeaders.map(header => header.text);
  worksheetTable.addRow(headers);

  // Agregar los datos de la tabla al worksheet
  this.filteredRutCountTable.forEach(item => {
    const row = [];
    this.tableHeaders.forEach(header => {
      row.push(item[header.value]);
    });
    worksheetTable.addRow(row);
  });


// Crear una nueva hoja de cálculo para la tabla de promedios
const worksheetPromedios = workbook.addWorksheet('Caracterización');

// Agregar los encabezados de las columnas de la tabla de promedios
const headers_promedio = this.promedioHeaders.map(header => header.text);
worksheetPromedios.addRow(headers_promedio);

// Agregar los datos de la tabla de promedios al worksheet
this.filteredPromediosTable.forEach(item => {
  const row = [];
  this.promedioHeaders.forEach(header => {
    row.push(item[header.value]);
  });
  worksheetPromedios.addRow(row);
});



  // Crear una nueva hoja de cálculo para el gráfico
  const worksheetChart = workbook.addWorksheet('Datos de gráfico');

  // Agregar los datos del gráfico al worksheet
  const labels = this.chartData.labels;
  const datasets = this.chartData.datasets;
  

// Agregar los encabezados de las columnas del gráfico
const chartColumnHeaders = ['Año', 'COHORTE', 'MATRICULAS'];

if (this.selectedCarrera) {
  chartColumnHeaders.push('PAR', 'VACANTES');
}

worksheetChart.addRow(chartColumnHeaders);

// Agregar los datos del gráfico al worksheet
for (let i = 0; i < labels.length; i++) {
  const label = labels[i];
  const data = datasets[0].data[i];
  const mat = datasets[1].data[i];
  const rowValues = [label, data, mat];

  if (this.selectedCarrera) {
    const par = datasets[2].data[i];
    const vacantes = datasets[3].data[i];
    rowValues.push(par, vacantes);
  }

  worksheetChart.addRow(rowValues);
}

  // Configurar el estilo de las celdas de la tabla
  worksheetTable.eachRow({ includeEmpty: true }, (row, rowNumber) => {
    row.eachCell((cell, colNumber) => {
      cell.font = { name: 'Arial', size: 10 };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });
  });

  // Configurar el estilo de las celdas del gráfico
  worksheetChart.eachRow({ includeEmpty: true }, (row, rowNumber) => {
    row.eachCell((cell, colNumber) => {
      cell.font = { name: 'Arial', size: 10 };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });
  });

  // Crear un Blob con los datos del workbook
  workbook.xlsx.writeBuffer().then(buffer => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);

    // Crear un enlace temporal y hacer clic en él para descargar el archivo
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'datos.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Liberar el objeto URL
    window.URL.revokeObjectURL(url);
  });
}

  }
};
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(151, 146, 146, 0.5); /* Fondo semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Asegura que el overlay esté por encima de otros elementos */
}

.loading-spinner {
  border: 4px solid #706e6e;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin-bottom: 16px;
}
</style>