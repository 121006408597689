
<template>
  <v-layout align-start>
      <v-flex>

        <v-card-title class="text-h6 text-md-h5 text-lg-h4">CARRERAS PROGRAMAS PLANES</v-card-title>        

          <v-toolbar flat color="white">

                <v-select
                    v-model="selectedAnho"
                    :items="anhos"
                    label="SELECCIONA AÑO">
                </v-select>

                <v-spacer></v-spacer>
                
              <v-text-field class="text-xs-center" v-model="search" append-icon="mdi-search-web" 
              label="Buscar plan" single-line hide-details></v-text-field>
              
              <v-spacer></v-spacer>

                <template v-if="esSuperadmin || esAdmin">
                    <v-btn color="#00A499" dark class="mb-2" v-on="on" @click="activarDesactivarMostrar">
                        <v-icon left>mdi-upload</v-icon>
                        Cargar archivo
                    </v-btn>
                </template>


              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                  <template  v-if="esSuperadmin || esAdmin" v-slot:activator="{ on }">
                      <v-btn color="#00A499" dark class="mb-2" v-on="on">
                        <v-icon left>mdi-plus-circle</v-icon>
                        Agregar PLan</v-btn>
                  </template>
                  <v-card>
                      <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                      </v-card-title>            
                      <v-card-text>
                      <v-container grid-list-md>
                          <v-layout wrap>
                              <v-flex xs12 sm6 md6>
                                  <v-text-field v-model="anho" label="Año">
                                  </v-text-field>
                              </v-flex>                   
                              <v-flex xs12 sm6 md6>
                                  <v-text-field v-model="anho_search" label="Año de búsqueda">
                                  </v-text-field>
                              </v-flex>                 
                              <v-flex xs12 sm12 md12>
                                  <v-text-field v-model="nombre_carrera" label="Nombre de la Carrera">                                        
                                  </v-text-field>
                              </v-flex>          
                              <v-flex xs12 sm12 md12>
                                  <v-text-field v-model="programa_sies" label="Nombre Programa SIES">                                        
                                  </v-text-field>
                              </v-flex>                                                                                                           
                             <v-flex xs12 sm12 md12>
                                  <v-text-field v-model="plan_nombre" label="Nombre del Plan">                                        
                                  </v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6 md6>
                                  <v-text-field v-model="cod_carrera" label="Código de Carrera">
                                  </v-text-field>
                              </v-flex>        
                              <v-flex xs12 sm6 md6>
                                  <v-text-field v-model="cod_sies" label="Código SIES">
                                  </v-text-field>
                              </v-flex>    
                              <v-flex xs12 sm6 md6>
                                  <v-text-field v-model="cod_plan" label="Código de Plan">
                                  </v-text-field>
                              </v-flex>                                                                                              
                              <!-- <v-flex xs12 sm6 md6>
                                  <v-select v-model="jornada_usach"
                                  :items="lista_jornada_usach" label="Jornada Usach">
                                  </v-select>
                              </v-flex>                -->
                              <v-flex xs12 sm6 md6>
                              <v-select
                                    v-model="jornada_usach"
                                    :items="jornadaUSACHList"
                                    label="Jornada USACH"
                                    item-value="value"
                                    item-text="text"
                                    autocomplete
                                ></v-select>
                              </v-flex>                                  
                              <!-- <v-flex xs12 sm6 md6>
                                  <v-select v-model="jornada_sies"
                                  :items="lista_jornada_sies" label="Jornada SIES">
                                  </v-select>
                              </v-flex>       -->
                              <v-flex xs12 sm6 md6>
                              <v-select
                                    v-model="jornada_sies"
                                    :items="jornadaSIESList"
                                    label="Jornada SIES"
                                    item-value="value"
                                    item-text="text"
                                    autocomplete
                                ></v-select>
                              </v-flex>    

                              <v-flex xs12 sm6 md6>
                                  <v-select v-model="estado_jornada"
                                  :items="lista_estado_jornada" label="Estado Jornada">
                                  </v-select>
                              </v-flex>    

                              <v-flex xs12 sm12 md12>
                                  <v-textarea v-model="observaciones" label="Observaciones" >                                        
                                  </v-textarea>
                              </v-flex>            
                                                                                                                                                                                  
                                                 
                              <v-flex xs12 sm12 md12 v-show="valida">
                                  <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">

                                  </div>
                              </v-flex>
                          </v-layout>
                      </v-container>
                      </v-card-text>            
                      <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="#00A499" flat @click="close">Cancelar</v-btn>
                      <v-btn color="#00A499" flat @click="guardar">Guardar</v-btn>
                      </v-card-actions>
                  </v-card>
              </v-dialog>

<template>
  <v-dialog v-model="adModal" max-width="290">
    <v-card>
      <v-card-title>
        Seleccionar archivo
      </v-card-title>
      <v-card-text>
        <v-file-input v-model="selectedFile" label="Archivo" @change="handleFileChange" ref="fileInput"></v-file-input>
      </v-card-text>
      <v-card-actions>
        <v-card-actions>
          <v-btn color="primary" @click="sendFile">Enviar</v-btn>
        </v-card-actions>
        <v-btn @click="activarDesactivarCerrar()" color="green darken-1" flat="flat">
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<template>
    <v-dialog v-model="showModal" max-width="500px">
      <v-card>
        <v-card-title>
          <h2 class="headline">Errores al Cargar</h2>
        </v-card-title>
        <v-card-text>
          <ul>
            <li v-for="error in modalErrors" :key="error.id_plan_anho">
              {{ error.error }} {{ error.id_plan_anho }}
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="exportErrorsToExcel">Exportar y Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>




<!-- Pop up de notificación -->
<v-snackbar v-model="showSnackbar" :color="snackbarColor" timeout="5000">
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="closeSnackbar">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
              
          </v-toolbar>
          <v-data-table
              :headers="filteredHeaders"
              :items="filteredItems"
              :search="search"
              class="elevation-1"
          >

<template  v-if="esSuperadmin || esAdmin" v-slot:[`item.acciones`]="{ item }">
        <v-icon
          small
          color="#00A499"
          class="mr-2"
          @click="editItem(item)"
        >
        mdi-pencil
        </v-icon>
        <v-icon
          small
          color="#00A499"
          class="mr-2"
          @click="deleteItem(item)"
        >
        mdi-delete
        </v-icon>        



        <template>
          <div>
            <!-- Formulario -->
            <form v-if="!showLoadingScreen">
              <!-- Contenido del formulario -->
            </form>

            <!-- Pantalla de espera -->
            <div v-if="showLoadingScreen" class="loading-screen">
              <div class="loading-overlay">
                <div class="loading-spinner"></div>
                <p>Cargando...</p>
              </div>
            </div>

            <!-- Resto de tu contenido -->
          </div>
        </template>






      </template>
              <template v-slot:items="props">
            <td>{{ props.item.id_plan_anho }}</td>
              <td>{{ props.item.anho }}</td>
              <td>{{ props.item.anho_search }}</td>
              <td>{{ props.item.plan_nombre }}</td>
              <td>{{ props.item.jornada_usach }}</td>
              <td>{{ props.item.jornada_sies }}</td>
              <td>{{ props.item.estado_jornada }}</td>
              <td>{{ props.item.cod_plan }}</td>
              <td>{{ props.item.cod_sies }}</td>
              <td>{{ props.item.id_anho_sies }}</td>
              <td>{{ props.item.programa_sies }}</td>
              <td>{{ props.item.observaciones }}</td>
              <td>{{ props.item.cod_carrera }}</td>
              <td>{{ props.item.nombre_carrera }}</td>             
              </template>
              <template v-slot:no-data>
              <v-btn color="#00A499" @click="listar()">Recargar</v-btn>
              </template>
          </v-data-table>
      </v-flex>
  </v-layout>

</template>

<script>
  import axios from 'axios'
  import { Workbook } from 'exceljs';
  export default {
      data(){
          return{
            selectedAnho: false,
            showModal: false, // Controla la visibilidad de la modal
            modalErrors: [], // Almacena los errores recibidos            
            showLoadingScreen: false,
            //   anho: [], // Arreglo de objetos con las opciones del select         
              dialog: false,

              showSnackbar: false,
              snackbarMessage: '',
              snackbarColor: '',
            //   errorMessage: '',
            //   showError: false,
              search:'',
              planes:[],
              headers: [
                  { text: 'Acciones', value: 'acciones', sortable: false },
                //   { text: 'Id Plan Año', value: 'id_plan_anho', sortable: true },
                  { text: 'Año', value: 'anho', sortable: true },
                  { text: 'Año de búsqueda', value: 'anho_search', sortable: false  },
                  { text: 'Nombre de Plan', value: 'plan_nombre', sortable: false  },
                  { text: 'Jornada USACH', value: 'jornada_usach', sortable: false  },
                  { text: 'Jornada SIES', value: 'jornada_sies', sortable: false  },
                  { text: 'Estado jornada', value: 'estado_jornada', sortable: false  } ,   
                  { text: 'Código de Plan', value: 'cod_plan', sortable: true },
                  { text: 'Código SIES', value: 'cod_sies', sortable: false  },
                //   { text: 'Id Año SIES', value: 'id_anho_sies', sortable: false  },
                  { text: 'Programa SIES', value: 'programa_sies', sortable: false  },
                  { text: 'Observaciones', value: 'observaciones', sortable: false  },
                  { text: 'Código Carrera', value: 'cod_carrera', sortable: false  },
                  { text: 'Nombre Carrera', value: 'nombre_carrera', sortable: false  }                 

              ],
              editedIndex: -1,
              id_plan_anho:'',
              // lista_jornada_sies: ['0','1','2','3','4','5'],
              // lista_jornada_usach: ['0','1','2'],
              lista_estado_jornada: ['OK','NOK'],
              valida:0,
              validaMensaje:[],
              jornadaSIESList:[],
              jornadaUSACHList:[],
              adModal:0,
          }
      },
      computed: {
        filteredHeaders() {
    // Filtrar los encabezados según el rol del usuario
    return this.headers.filter(header => {
      return header.value !== 'acciones' || this.esSuperadmin || this.esAdmin;
    });
  },
          formTitle () {
          return this.editedIndex === -1 ? 'Nuevo registro' : 'Editar registro'
          },
          filteredItems() {
            return this.selectedAnho ? this.planes.filter(item => item.anho === this.selectedAnho) : this.planes;      
    },
    logueado(){
      return this.$store.state.email;
    },
    esSuperadmin(){
      return this.$store.state.email && this.$store.state.rol == 'Superadmin';
    },
    esAdmin(){
      return this.$store.state.email && this.$store.state.rol == 'Admin';
    },
    esUsuario(){
      return this.$store.state.email && this.$store.state.rol == 'Usuario';
    },
    email() {
      return this.$store.state.email;
    },
    rol() {
      return this.$store.state.rol;
    }
      },
      watch: {
          dialog (val) {
          val || this.close()
          }
      },
      created () {
          this.listar()
          this.listarJornadaSIES();      
          this.listarJornadaUSACH();                        

      },
      methods: {

        listarJornadaSIES() {
                let me = this;
                axios
                    .get('http://158.170.66.74:8080/jornada_sies/list')
                    .then(function (response) {
                    me.jornadaSIESList = response.data.map((item) => ({
                        value: item.CODIGO_JORNADA,
                        text: item.NOMBRE_JORNADA,
                    }));
                    })
                    .catch(function (error) {
                    console.log(error);
                    });
            },

            listarJornadaUSACH() {
                let me = this;
                axios
                    .get('http://158.170.66.74:8080/jornada_plan/list')
                    .then(function (response) {
                    me.jornadaUSACHList = response.data.map((item) => ({
                        value: item.CODIGO_JORNADA,
                        text: item.NOMBRE_JORNADA,
                    }));
                    })
                    .catch(function (error) {
                    console.log(error);
                    });
            },


          exportErrorsToExcel() {
              const workbook = new Workbook();
              const worksheet = workbook.addWorksheet('Errores');

              // Escribir encabezados de columna
              worksheet.addRow(['Error', 'ID Plan Año']);

              // Escribir errores en las filas
              this.modalErrors.forEach(error => {
                worksheet.addRow([error.error, error.id_plan_anho]);
              });

              // Guardar el archivo Excel
              workbook.xlsx.writeBuffer().then(buffer => {
                // Crear un Blob a partir del buffer
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                // Crear una URL para descargar el archivo
                const url = URL.createObjectURL(blob);

                // Crear un enlace de descarga y hacer clic en él para iniciar la descarga
                const link = document.createElement('a');
                link.href = url;
                link.download = 'errores.xlsx';
                link.click();

                // Liberar la URL creada
                URL.revokeObjectURL(url);
                this.showModal = false;

              });
            },

        sendFile() {
          this.adModal = 0;
          this.showLoadingScreen = true;
          const formData = new FormData();
          formData.append('csv', this.selectedFile);

          fetch('http://158.170.66.74:8080/cpp/insert', {
            method: 'POST',
            body: formData
          })
            .then(response => response.json())
            .then(data => {
              console.log('Respuesta de la API:', data);
              if (data.status === 'OK') {
                // Cerrar la modal después de enviar el archivo
                // this.adModal = 0;
                this.showLoadingScreen = false;

                this.showSnackbar = true;
                this.snackbarMessage = 'Archivo enviado exitosamente.';
                this.snackbarColor = 'success';
              } else if (data.errors && data.errors.length > 0) {
                // Mostrar los errores en la modal
                this.showLoadingScreen = false;

                // this.adModal = 0; // Cierra la modal del formulario
                this.showModal = true; // Mostrar la modal donde se mostrarán los errores
                this.modalErrors = data.errors; // Asignar los errores a la variable modalErrors
              } else {
                this.adModal = 0;
                this.showSnackbar = true;
                this.snackbarMessage = 'Error al enviar el archivo.';
                this.snackbarColor = 'error';
              }

              // Limpiar el campo de selección de archivo
              this.$refs.fileInput.reset();

              this.selectedFile = null;
            });
    },
openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    closeSnackbar() {
      this.showSnackbar = false;
      this.snackbarMessage = '';
      this.snackbarColor = '';
    },

        listar() {
        let me = this;
        let header = { "Token": this.$store.state.token };
        let configuracion = { headers: header };
        axios.get('http://158.170.66.74:8080/cpp/list', configuracion).then(function (response) {
            me.planes = response.data;
            // Obtener valores únicos para el filtro
            me.anhos = [...new Set(me.planes.map(item => item.anho))].sort();
        }).catch(function (error) {
            console.log(error);
        });

          },
          
          limpiar(){
              this.id_plan_anho='';
              this.anho='';
              this.anho_search='';
              this.plan_nombre='';
              this.jornada_usach='';
              this.jornada_sies='';
              this.estado_jornada='';
              this.cod_plan='';
              this.cod_sies='';
              this.id_anho_sies='';
              this.estado_jornada='';
              this.programa_sies='';
              this.observaciones='';              
              this.cod_carrera='';              
              this.nombre_carrera='';              
              this.valida=0;
              this.validaMensaje=[];
              this.editedIndex=-1;
          },
         validar(){
              this.valida=0;
              this.validaMensaje=[];
            //   if(this.anho.length<1 || this.nombre.length>50){
            //       this.validaMensaje.push('El nombre de la persona debe tener entre 1-50 caracteres.');
            //   }
              if(!Number.isInteger(Number(this.anho))){
                  this.validaMensaje.push('Debe ingresar un año válido');
              }
              if(!Number.isInteger(Number(this.anho_search))){
                  this.validaMensaje.push('Debe ingresar un año de búsqueda válido');
              }
              if(!this.plan_nombre || this.plan_nombre.length<5){
                  this.validaMensaje.push('Ingrese un Nombre Plan válido.');
              }
              if(!this.programa_sies || this.programa_sies.length<5){
                  this.validaMensaje.push('Ingrese un Nombre Programa SIES válido.');
              }
              if(!this.nombre_carrera || this.nombre_carrera.length<5){
                  this.validaMensaje.push('Ingrese un Nombre de Carrera válido.');
              }          
              if(!this.cod_carrera){
                  this.validaMensaje.push('Ingrese un Código de Carrera válido.');
              }       
              if(!Number.isInteger(Number(this.jornada_sies))){
                  this.validaMensaje.push('Favor seleccione Jornada SIES');
              }           
              if(!Number.isInteger(Number(this.jornada_usach))){
                  this.validaMensaje.push('Favor seleccione Jornada USACH');
              }       
              if(!Number.isInteger(Number(this.cod_plan))){
                  this.validaMensaje.push('Debe ingresar un Código de Plan válido');
              }   
              if(!this.cod_sies || this.cod_sies.length<7){
                  this.validaMensaje.push('Ingrese un Código SIES válido.');
              }                                                                          
              if (this.validaMensaje.length){
                  this.valida=1;
              }
              return this.valida;
          },
          guardar(){
              let me=this;
            //   let header={"Token" : this.$store.state.token};
             //  let configuracion= {headers : header};
              if (this.validar()){
                  return;
              }
              if (this.editedIndex >-1){
                  //Código para editar
                  axios.put(`http://158.170.66.74:8080/cpp/update/${this.id_plan_anho}`,{
                      'id_plan_anho':this.cod_plan+this.anho,
                      'anho':this.anho,
                      'anho_search':this.anho_search,
                      'plan_nombre':this.plan_nombre,
                      'jornada_usach':this.jornada_usach,
                      'jornada_sies':this.jornada_sies,
                      'estado_jornada': this.estado_jornada,
                      'cod_plan':this.cod_plan,
                      'cod_sies':this.cod_sies,
                      'id_anho_sies':this.anho+this.cod_sies,
                      'programa_sies':this.programa_sies,
                      'observaciones':this.observaciones,
                      'cod_carrera': this.cod_carrera,
                      'nombre_carrera':this.nombre_carrera
             //     },configuracion)
                  })
                  .then(function(response){
                      me.limpiar();
                      me.close();
                      me.listar();
                  })
                  .catch(function(error){
                      console.log(error);
                  });
              }else{
                  //Código para guardar
                  axios.post('http://158.170.66.74:8080/cpp/add',{
                      'id_plan_anho':this.cod_plan+this.anho,                    
               //       'id_plan_anho':this.id_plan_anho,
                      'anho':this.anho,
                      'anho_search':this.anho_search,
                      'plan_nombre':this.plan_nombre,
                      'jornada_usach':this.jornada_usach,
                      'jornada_sies':this.jornada_sies,
                      'estado_jornada': this.estado_jornada || ' ',
                      'cod_plan':this.cod_plan,
                      'cod_sies':this.cod_sies,
                    //   'id_anho_sies':this.plan_nombre,
                      'id_anho_sies':this.anho+this.cod_sies,
                      'programa_sies':this.programa_sies,
                      'observaciones':this.observaciones || ' ',
                      'cod_carrera': this.cod_carrera,
                      'nombre_carrera':this.nombre_carrera
                  })
                  .then(function(response){
                      me.limpiar();
                      me.close();
                      me.listar();
                  })
                  .catch(function(error){
                      console.log(error);
                  });
              }
          },
          editItem (item) {
              this.id_plan_anho=item.id_plan_anho,
              this.anho=item.anho;
              this.anho_search=item.anho_search;
              this.plan_nombre=item.plan_nombre;
              this.jornada_usach=item.jornada_usach;
              this.jornada_sies=item.jornada_sies;
              this.estado_jornada=item.estado_jornada;
              this.cod_plan=item.cod_plan;
              this.cod_sies=item.cod_sies;
              this.id_anho_sies=item.id_anho_sies,
              this.programa_sies=item.programa_sies;
              this.observaciones=item.observaciones;       
              this.cod_carrera=item.cod_carrera;
              this.nombre_carrera=item.nombre_carrera;                         
              this.dialog = true;
              this.editedIndex=1;
          },
          activarDesactivarMostrar(accion,item){
              this.adModal=1;
              this.adNombre=item.nombre;
              this.adId=item._id;
              if (accion==1){
                  this.adAccion=1;
              } else if(accion==2){
                  this.adAccion=2;
              } else{
                  this.adModal=0;
              }
          },
          activarDesactivarCerrar(){
              this.adModal=0;
          },



        async deleteItem(item) {
            if (confirm('¿Estás seguro de que quieres eliminar este elemento?')) {
        try {
          // Hacer la petición DELETE a la API con Axios
          const respuesta = await axios.delete(`http://158.170.66.74:8080/cpp/delete/${item.id_plan_anho}`);
          
       //   Verificar si la petición fue exitosa y mostrar un mensaje al usuario
          if (respuesta.status != 200) {
            throw new Error('Error al eliminar el elemento');
          }
          await this.listar();
        } catch (error) {
          console.error(error);
          alert('Ocurrió un error al eliminar el elemento');
        }
        this.planes.splice(this.editedIndex, 1)

    }

      },

          close () {
              this.dialog = false;
          }
      }
  }
</script>

<style>
/* Estilos para la pantalla de espera */

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loading-overlay {
  background-color: rgba(245, 241, 241, 0.5); /* Cambiar el valor de la opacidad aquí */
  backdrop-filter: blur(8px); /* Agregar el efecto de desenfoque */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 8px;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>