<template>
  <hello-world />
</template>

<script>
  import HelloWorld from '../components/InTitNoCpp'

  export default {
    name: 'titnoencpp',

    components: {
      HelloWorld,
    },
  }
</script>
