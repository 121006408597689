<template>
  <hello-world />
</template>

<script>
  import HelloWorld from '../components/InMatNoCpp'

  export default {
    name: 'noencpp',

    components: {
      HelloWorld,
    },
  }
</script>
