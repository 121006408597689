import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CppView from '../views/CppView.vue'
import InMatNoCpp from '../views/InMatNoCpp.vue'
import InTitNoCpp from '../views/InTitNoCpp.vue'
import Users from '../views/Users.vue'
import Login from '../views/Login.vue'
import NivelGlobal from '../views/Nivel_global.vue'
import TableDefinitions from '../views/Tables_definitions.vue'
import JornadaSIES from '../views/Jornada_SIES.vue'
import Graphic from '../views/GraphicView.vue'
import FormB2 from '../views/FormB2View.vue'
import IngPrimerAnho from '../views/Ing_Primer_Anho.vue'
import Carreras from '../views/Carrera.vue'
import Unidades from '../views/Unidad.vue'
import TiposCarreras from '../views/Tipo_Carrera.vue'
import JornadaUsach from '../views/JornadaUsach.vue'
import ModalidadCarrera from '../views/ModalidadCarrera.vue'
import NivelCarrera from '../views/NivelCarrera.vue'
import RegimenSIES from '../views/RegimenSIES.vue'
import AreaOECDSIES from '../views/AreaOECDSIES.vue'
import SubareaOECDSIES from '../views/SubareaOECDSIES.vue'
import AreaActualSIES from '../views/AreaActualSIES.vue'
import VigenciaCarreraSIES from '../views/VigenciaCarreraSIES.vue'
import AcreditacionSIES from '../views/AcreditacionSIES.vue'
import TipoProgramaSIES from '../views/TipoProgramaSIES.vue'
import AreaConocimientoSIES from '../views/AreaConocimientoSIES.vue'
import DefAnhoAcademico from '../views/DefAnhoAcademico.vue'
import Indicadores_1 from '../views/Indicadores_1View.vue'
import Indicadores_2 from '../views/Indicadores_2View.vue'


import store from '../store/index'
import jwt_decode from 'jwt-decode';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },
  {
    path: '/noencpp',
    name: 'InMatNoCpp',
    component: InMatNoCpp,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },
  {
    path: '/titnoencpp',
    name: 'InTitNoCpp',
    component: InTitNoCpp,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },
  {
    path: '/cpp',
    name: 'CppView',
    component: CppView,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: { requiresAuth: true, roles: ['Superadmin'] }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/nivel_global',
    name: 'NivelGlobal',
    component: NivelGlobal,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },
  {
    path: '/table_definitions',
    name: 'TableDefinitions',
    component: TableDefinitions,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },
  {
    path: '/jornada_sies',
    name: 'JornadaSIES',
    component: JornadaSIES,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },
  {
    path: '/graphics',
    name: 'Graphic',
    component: Graphic,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },
  {
    path: '/formb2',
    name: 'FormB2',
    component: FormB2,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },
  {
    path: '/ing_primer_anho',
    name: 'IngPrimerAnho',
    component: IngPrimerAnho,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },
  {
    path: '/def_anho_acad',
    name: 'DefAnhoAcademico',
    component: DefAnhoAcademico,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },
  {
    path: '/carreras',
    name: 'Carreras',
    component: Carreras,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },
  {
    path: '/unidades',
    name: 'Unidades',
    component: Unidades,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },
  {
    path: '/tipo_carrera',
    name: 'TiposCarreras',
    component: TiposCarreras,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },
  {
    path: '/jornada_usach',
    name: 'JornadaUsach',
    component: JornadaUsach,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },
  {
    path: '/modalidad_carrera',
    name: 'ModalidadCarrera',
    component: ModalidadCarrera,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },
  {
    path: '/nivel_carrera',
    name: 'NivelCarrera',
    component: NivelCarrera,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },
  {
    path: '/regimen_sies',
    name: 'RegimenSIES',
    component: RegimenSIES,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },
  {
    path: '/area_oecd_sies',
    name: 'AreaOECDSIES',
    component: AreaOECDSIES,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },  
  {
    path: '/subarea_oecd_sies',
    name: 'SubareaOECDSIES',
    component: SubareaOECDSIES,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },  
  {
    path: '/vigencia_carrera_sies',
    name: 'VigenciaCarreraSIES',
    component: VigenciaCarreraSIES,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },    
    {
    path: '/area_actual_sies',
    name: 'AreaActualSIES',
    component: AreaActualSIES,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },  
  {
    path: '/acreditacion_sies',
    name: 'AcreditacionSIES',
    component: AcreditacionSIES,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },  
  {
    path: '/tipo_programa_sies',
    name: 'TipoProgramaSIES',
    component: TipoProgramaSIES,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },  
  {
    path: '/area_conocimiento_sies',
    name: 'AreaConocimientoSIES',
    component: AreaConocimientoSIES,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },  
  {
    path: '/indicadores_1',
    name: 'Indicadores_1',
    component: Indicadores_1,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },
  {
    path: '/indicadores_2',
    name: 'Indicadores_2',
    component: Indicadores_2,
    meta: { requiresAuth: true, roles: ['Usuario','Admin', 'Superadmin'] }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const roles = to.meta.roles;
  const token = localStorage.getItem('token');

  if (requiresAuth && !token) {
    next('/login');
  } else if (requiresAuth && token) {
    const decoded = jwt_decode(token);
    const userRole = decoded.rol; 
    const userEmail = decoded.email; 

    if (roles.includes(userRole)) {
      store.commit('setToken', token);
      store.commit('setRol', userRole); //utiliza la mutación setRol para asignar el valor del rol al state
      store.commit('setEmail', userEmail);

      next();
    } else {
      next('/login');
    }
  } else {
    next();
  }
});
export default router
