<template>
    <v-layout align-start>
        <v-flex>
            <v-card-title class="text-h6 text-md-h5 text-lg-h4">CARRERAS</v-card-title>        

            <p class="font-weight-light">
      Define las carreras existentes en la universidad con su código correspondiente.
    </p>
            <v-toolbar flat color="white">
                <v-spacer></v-spacer>
                <v-text-field class="text-xs-center" v-model="search" append-icon="mdi-search-web" 
                label="Buscar Carrera" single-line hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                    <template  v-if="esSuperadmin || esAdmin" v-slot:activator="{ on }">
                        <v-btn color="#00A499" dark class="mb-2" v-on="on">Agregar Carrera</v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                        </v-card-title>            
                        <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>

                                <v-flex xs12 sm6 md6>
                                    <v-text-field v-model="CODIGO_CARRERA" label="Id Carrera">
                                    </v-text-field>
                                </v-flex>                   
                                <v-flex xs12 sm6 md6>
                                    <v-text-field v-model="NOMBRE_CARRERA" label="Carrera">
                                    </v-text-field>
                                </v-flex>           

                                <v-select
                                    v-model="CODIGO_UMENOR"
                                    :items="unidadList"
                                    label="Unidad menor"
                                    item-value="value"
                                    item-text="text"
                                    autocomplete
                                ></v-select>
                                <v-select
                                    v-model="CODIGO_TIPO_CARRERA"
                                    :items="tipoCarreraList"
                                    label="Tipo carrera"
                                    item-value="value"
                                    item-text="text"
                                    autocomplete
                                ></v-select>

                                <!-- <v-flex xs12 sm6 md6>
                                        <v-select v-model="CODIGO_UMENOR" :items="unidadList" label="Unidad menor" item-value="value" item-text="text"></v-select>                                        
                                    </v-flex>                                         
                                    <v-flex xs12 sm6 md6>
                                        <v-select v-model="CODIGO_TIPO_CARRERA" :items="tipoCarreraList" label="Tipo carrera" item-value="value" item-text="text"></v-select>                                        
                                    </v-flex>                                                                                                                                                    
                                <v-flex xs12 sm12 md12 v-show="valida">
                                    <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
  
                                    </div>
                                </v-flex> -->
                            </v-layout>
                        </v-container>
                        </v-card-text>            
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#00A499" flat @click="close">Cancelar</v-btn>
                        <v-btn color="#00A499" flat @click="guardar">Guardar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
               
            </v-toolbar>
            <v-data-table
                :headers="filteredHeaders"
                :items="CARRERA"
                :search="search"
                class="elevation-1"
            >
  
    <template  v-if="esSuperadmin || esAdmin" v-slot:[`item.acciones`]="{ item }">

          <v-icon
            small
            color="#00A499"
            class="mr-2"
            @click="editItem(item)"
          >
          mdi-pencil
          </v-icon>
          <v-icon
            small
            color="#00A499"
            class="mr-2"
            @click="deleteItem(item)"
          >
          mdi-delete
          </v-icon>         
        </template>

                <template v-slot:items="props">
              <td>{{ props.item.CODIGO_CARRERA }}</td>
                <td>{{ props.item.NOMBRE_CARRERA }}</td>
                <td>{{ props.item.CODIGO_UMENOR }}</td>
                <td>{{ props.item.CODIGO_TIPO_CARRERA }}</td>                
                </template>
                <template v-slot:no-data>
                <v-btn color="#00A499" @click="listar()">Resetear</v-btn>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
  </template>
  
  <script>
    import axios from 'axios'
    export default {
        data(){
            return{
                dialog: false,
                search:'',
                CARRERA:[],
                headers: [
                    { text: 'Acciones', value: 'acciones', sortable: false },
                    { text: 'Id Carrera', value: 'CODIGO_CARRERA', sortable: true },
                    { text: 'Carrera', value: 'NOMBRE_CARRERA', sortable: true },
                    { text: 'Código Unidad Menor', value: 'CODIGO_UMENOR', sortable: true },
                    { text: 'Código Tipo Carrera', value: 'CODIGO_TIPO_CARRERA', sortable: true }                    
                ],
                editedIndex: -1,
                CODIGO_CARRERA: '',
                NOMBRE_CARRERA: '',
                CODIGO_UMENOR: '',
                CODIGO_TIPO_CARRERA: '',      
                tipoCarreraList: [], // Lista para almacenar los tipos de carrera obtenidos de la API                 
                unidadList: [], // Lista para almacenar los tipos de carrera obtenidos de la API                          
                valida:0,
                validaMensaje:[],
            }
        },
        computed: {
            logueado(){
            return this.$store.state.email;
            },
            esSuperadmin(){
            return this.$store.state.email && this.$store.state.rol == 'Superadmin';
            },
            esAdmin(){
            return this.$store.state.email && this.$store.state.rol == 'Admin';
            },
            esUsuario(){
            return this.$store.state.email && this.$store.state.rol == 'Usuario';
            },
            filteredHeaders() {
            // Filtrar los encabezados según el rol del usuario
            return this.headers.filter(header => {
            return header.value !== 'acciones' || this.esSuperadmin || this.esAdmin;
            });
            },
            formTitle () {
            return this.editedIndex === -1 ? 'Nuevo Carrera' : 'Editar Carrera'
            }
        },
        watch: {
            dialog (val) {
            val || this.close()
            }
        },
        created () {
            this.listar();
            this.listarTipoCarrera();       
            this.listarUmenor();            
        },
        methods: {

            listar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                axios.get('http://158.170.66.74:8080/carreras/list',configuracion).then(function (response){
                    me.CARRERA=response.data;
                }).catch(function(error){
                    console.log(error);
                });
  
            },
            listarTipoCarrera() {
                let me = this;
                axios
                    .get('http://158.170.66.74:8080/tipo_carrera/list')
                    .then(function (response) {
                    me.tipoCarreraList = response.data.map((item) => ({
                        value: item.CODIGO_TIPO_CARRERA,
                        text: item.NOMBRE_TIPO_CARRERA,
                    }));
                    })
                    .catch(function (error) {
                    console.log(error);
                    });
            },
            listarUmenor() {
                let me = this;
                axios
                    .get('http://158.170.66.74:8080/unidad/list')
                    .then(function (response) {
                    me.unidadList = response.data.map((item) => ({
                        value: item.CODIGO_UMENOR,
                        text: item.NOMBRE_UNIDAD,
                    }));
                    })
                    .catch(function (error) {
                    console.log(error);
                    });
            },

                
            limpiar(){
              //  this.id='';
                this.CODIGO_CARRERA='';
                this.NOMBRE_CARRERA='';     
                this.CODIGO_UMENOR='';
                this.CODIGO_TIPO_CARRERA='';                     
                this.valida=0;
                this.validaMensaje=[];
                this.editedIndex=-1;
            },
           validar(){
                this.valida=0;
                this.validaMensaje=[];
                if(!this.CODIGO_CARRERA){
                    this.validaMensaje.push('Ingrese un código válido.');
                }
                if(!this.NOMBRE_CARRERA){
                    this.validaMensaje.push('Ingrese un Carrera válido.');
                }
                if(!Number.isInteger(Number(this.CODIGO_UMENOR))){
                  this.validaMensaje.push('Debe código válido');
              }           
                if(!this.CODIGO_TIPO_CARRERA){
                    this.validaMensaje.push('Ingrese tipo válido.');
                }                    
                if (this.validaMensaje.length){
                    this.valida=1;
                }
                return this.valida;
            },
            guardar(){
                let me=this;
              //   let header={"Token" : this.$store.state.token};
               //  let configuracion= {headers : header};
                if (this.validar()){
                    return;
                }
                if (this.editedIndex >-1){
                    //Código para editar
                    
                    axios.put(`http://158.170.66.74:8080/carreras/update/${this.CODIGO_CARRERA}`,{
                        'CODIGO_CARRERA':this.CODIGO_CARRERA,
                        'NOMBRE_CARRERA':this.NOMBRE_CARRERA,
                        'CODIGO_UMENOR':this.CODIGO_UMENOR,
                        'CODIGO_TIPO_CARRERA':this.CODIGO_TIPO_CARRERA 
               //     },configuracion)
                    })
                    .then(function(response){
                        me.limpiar();
                        me.close();
                        me.listar();
                    })
                    .catch(function(error){
                        console.log(error);
                    });
                }else{
                    //Código para guardar
                    axios.post('http://158.170.66.74:8080/carreras/add',{
                        'CODIGO_CARRERA':this.CODIGO_CARRERA,
                        'NOMBRE_CARRERA':this.NOMBRE_CARRERA,
                        'CODIGO_UMENOR':this.CODIGO_UMENOR,
                        'CODIGO_TIPO_CARRERA':this.CODIGO_TIPO_CARRERA                   
                    })
                    .then(function(response){
                        me.limpiar();
                        me.close();
                        me.listar();
                    })
                    .catch(function(error){
                        console.log(error);
                    });
                }
            },
            editItem (item) {
                this.CODIGO_CARRERA=item.CODIGO_CARRERA;              
                this.NOMBRE_CARRERA=item.NOMBRE_CARRERA;
                this.CODIGO_UMENOR=item.CODIGO_UMENOR;              
                this.CODIGO_TIPO_CARRERA=item.CODIGO_TIPO_CARRERA;                
                this.dialog = true;
                this.editedIndex=1;
            },

  
            async deleteItem(item) {
  if (confirm('¿Estás seguro de que quieres eliminar este elemento?')) {
    try {
      // Hacer la petición DELETE a la API con Axios
      const respuesta = await axios.delete(`http://158.170.66.74:8080/carreras/delete/${item.CODIGO_CARRERA}`);
      
      // Verificar si la petición fue exitosa y mostrar un mensaje al usuario
      if (respuesta.status !== 200) {
        throw new Error('Error al eliminar el elemento');
      }

      // Esperar a que la eliminación se complete antes de obtener los datos actualizados
      await this.listar();
    } catch (error) {
      console.error(error);
      alert('Ocurrió un error al eliminar el elemento');
    }
  }
},

  
            close () {
                this.dialog = false;
            }
        }
    }
  </script>
  