
<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar flat color="white">
                <v-toolbar-title>OBJETOS DE LAS BASES DE DATOS</v-toolbar-title>
                <v-divider
                class="mx-2"
                inset
                vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-text-field class="text-xs-center" v-model="search" append-icon="mdi-search-web" 
                label="Buscar objeto" single-line hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                    <template  v-if="esSuperadmin || esAdmin" v-slot:activator="{ on }">
                        <v-btn color="#00A499" dark class="mb-2" v-on="on">Agregar Objeto</v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                        </v-card-title>            
                        <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12 sm6 md6>
                                    <v-text-field v-model="name" label="Nombre objeto">
                                    </v-text-field>
                                </v-flex>                   
                                <v-flex xs12 sm6 md6>
                                    <v-select v-model="type"
                                    :items="lista_tipo_objeto" label="Tipo de objeto">
                                    </v-select>
                                </v-flex>               
                                <v-flex xs12 sm12 md12>
                                    <v-text-field v-model="database" label="Base de datos">                                        
                                    </v-text-field>
                                </v-flex>          
                                <v-flex xs12 sm12 md12>
                                    <v-textarea v-model="description" label="Descripción del objeto">                                        
                                    </v-textarea>
                                </v-flex>                                                                                                                                                                                                                                                                                                                               
        
                                <v-flex xs12 sm12 md12 v-show="valida">
                                    <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
  
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-container>
                        </v-card-text>            
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#00A499" flat @click="close">Cancelar</v-btn>
                        <v-btn color="#00A499" flat @click="guardar">Guardar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-toolbar>
            <v-data-table
                :headers="filteredHeaders"
                :items="objetos"
                :search="search"
                class="elevation-1"
            >
  
  
  <template v-slot:[`item.acciones`]="{ item }">
          <v-icon
            small
            color="#00A499"
            class="mr-2"
            @click="editItem(item)"
          >
          mdi-pencil
          </v-icon>
          <v-icon
            small
            color="#00A499"
            class="mr-2"
            @click="deleteItem(item)"
          >
          mdi-delete
          </v-icon>        
         
        </template>
                <template v-slot:items="props">
              <td>{{ props.item.name }}</td>
                <td>{{ props.item.type }}</td>
                <td>{{ props.item.database }}</td>
                <td>{{ props.item.description }}</td>         
                </template>
                <template v-slot:no-data>
                <v-btn color="#00A499" @click="listar()">Resetear</v-btn>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
  </template>
  
  <script>
    import axios from 'axios'
    export default {
        data(){
            return{
                dialog: false,
                search:'',
                objetos:[],
                headers: [
                    { text: 'Acciones', value: 'acciones', sortable: false },
                    { text: 'Nombre', value: 'name', sortable: true },
                    { text: 'Tipo de objeto', value: 'type', sortable: false  },
                    { text: 'Base de datos', value: 'database', sortable: false  },
                    { text: 'Descripćión', value: 'description', sortable: false  }             
                ],
                editedIndex: -1,
                id:'',
                name:'',
                type:'',
                database:'',
                description:'',
              //   tipo_persona:'Proveedor',
              lista_tipo_objeto: ['Tabla','Vista','Procedimiento Almacenado','Trigger','Función'],
                valida:0,
                validaMensaje:[],
              //    adModal:0,
              //   adAccion:0,
              //   adNombre:'',
              //   adId:''
            }
        },
        computed: {

            logueado(){
            return this.$store.state.email;
            },
            esSuperadmin(){
            return this.$store.state.email && this.$store.state.rol == 'Superadmin';
            },
            esAdmin(){
            return this.$store.state.email && this.$store.state.rol == 'Admin';
            },
            esUsuario(){
            return this.$store.state.email && this.$store.state.rol == 'Usuario';
            },
            filteredHeaders() {
        // Filtrar los encabezados según el rol del usuario
        return this.headers.filter(header => {
        return header.value !== 'acciones' || this.esSuperadmin || this.esAdmin;
        });
      },
            formTitle () {
            return this.editedIndex === -1 ? 'Nuevo registro' : 'Editar registro'
            }
        },
        watch: {
            dialog (val) {
            val || this.close()
            }
        },
        created () {
            this.listar()
        },
        methods: {

            listar(){
                let me=this;
                let header={"Token" : this.$store.state.token};
                let configuracion= {headers : header};
                axios.get('http://158.170.66.74:8080/table_definitions/list',configuracion).then(function (response){
                    me.objetos=response.data;
                }).catch(function(error){
                    console.log(error);
                });
  
            },
            
            limpiar(){
                this.id='';
                this.name='';
                this.type='';
                this.database='';
                this.description='';  
                this.valida=0;
                this.validaMensaje=[];
                this.editedIndex=-1;
            },
           validar(){
                this.valida=0;
                this.validaMensaje=[];

                if(!this.name || this.name.length<1){
                    this.validaMensaje.push('Ingrese un Nombre objeto válido.');
                }
                if(!this.type || this.type.length<1){
                    this.validaMensaje.push('Ingrese un tipo de elemento válido.');
                }
                if(!this.database || this.database.length<1){
                    this.validaMensaje.push('Ingrese una base de datos válida.');
                }        
                if(!this.description || this.description.length<5){
                    this.validaMensaje.push('Ingrese una descripción válida.');
                }                                                                                             
                if (this.validaMensaje.length){
                    this.valida=1;
                }
                return this.valida;
            },
            guardar(){
                let me=this;
              //   let header={"Token" : this.$store.state.token};
               //  let configuracion= {headers : header};
                if (this.validar()){
                    return;
                }
                if (this.editedIndex >-1){
                    //Código para editar
                    axios.put(`http://158.170.66.74:8080/table_definitions/update/${this.id}`,{
                        'id':this.id,
                        'name':this.name,
                        'type':this.type,
                        'database':this.database,
                        'description':this.description,
               //     },configuracion)
                    })
                    .then(function(response){
                        me.limpiar();
                        me.close();
                        me.listar();
                    })
                    .catch(function(error){
                        console.log(error);
                    });
                }else{
                    //Código para guardar
                    axios.post('http://158.170.66.74:8080/table_definitions/add',{
                        'name':this.name,                    
                        'type':this.type,
                        'database':this.database,
                        'description':this.description
                    })
                    .then(function(response){
                        me.limpiar();
                        me.close();
                        me.listar();
                    })
                    .catch(function(error){
                        console.log(error);
                    });
                }
            },
            editItem (item) {
                this.id=item.id;
                this.name=item.name;
                this.type=item.type;
                this.database=item.database;
                this.description=item.description;               
                this.dialog = true;
                this.editedIndex=1;
            },
 
  
          async deleteItem(item) {
              if (confirm('¿Estás seguro de que quieres eliminar este elemento?')) {
          try {
            // Hacer la petición DELETE a la API con Axios
            const respuesta = await axios.delete(`http://158.170.66.74:8080/table_definitions/delete/${item.id}`);
            
         //   Verificar si la petición fue exitosa y mostrar un mensaje al usuario
            if (respuesta.status != 200) {
              throw new Error('Error al eliminar el elemento');
            }
          } catch (error) {
            console.error(error);
            alert('Ocurrió un error al eliminar el elemento');
          }
          this.objetos.splice(this.editedIndex, 1)
  
      }
  
        },
  
            close () {
                this.dialog = false;
            }
        }
    }
  </script>
  